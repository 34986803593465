import React from 'react';
import { createMuiTheme, ThemeProvider as BaseThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#FFDA54',
      main: '#FAA91A',
      dark: '#C27A00',
      contrastText: '#000000',
    },
  },
  typography: {
    fontFamily: ['"Rubik"', '"Kanit"', 'san-serif'].join(','),
  },
});

const ThemeProvider = ({ ...props }) => {
  return <BaseThemeProvider theme={theme} {...props} />;
};

export default ThemeProvider;

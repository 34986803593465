/**
 * This component modified from Material-UI drawer Example.
 * See the link below for more information.
 * https://material-ui.com/components/drawers/#mini-variant-drawer
 */

import React from 'react';
import { generatePath, useHistory, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { useAuthContext } from '../../../domains/auth/hooks';
import { routes } from '../routes';

import NavBar from './NavBar';
import AppTitleBar from './AppTitleBar';
import AppLogoBar from './AppLogoBar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    marginTop: '64px',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
  },
}));

export default function AppLayout({ children }) {
  const classes = useStyles();
  const { authState, AUTH_STATE, logout, userInfo } = useAuthContext();
  const history = useHistory();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
    } finally {
      history.push(generatePath(routes.LOGIN.path));
    }
  };

  if (authState === AUTH_STATE.NOT_LOGGED_IN) {
    return <Redirect to={generatePath(routes.LOGIN.path)} />;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppLogoBar />
      <AppTitleBar drawerOpen={open} onDrawerOpen={handleDrawerOpen} />
      <NavBar
        open={open}
        onClose={handleDrawerClose}
        onLogout={handleLogout}
        userInfo={userInfo}
        isLogin={authState === AUTH_STATE.LOGGED_IN}
      />

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}

import MuiTextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

const useInputStyles = makeStyles({
  inputRootReadOnly: {
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.09)',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    '&:after': {
      borderBottom: 'none',
    },
    cursor: 'default',
  },
  inputLabelRootReadOnly: {
    color: 'rgba(0, 0, 0, 0.54) !important',
  },
  checkboxLabel: {
    height: '48px',
  },
});

const TextFieldCheckbox = ({
  value,
  label,
  onChange,
  onBlur,
  name,
  helperText,
  error,
  labelPlacement,
  fullWidth,
  readOnly,
  disabled,
  required,
  ...restProps
}) => {
  const classes = useInputStyles();
  return (
    <FormControl required error={Boolean(error)} variant={'filled'} fullWidth={fullWidth}>
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            onChange={onChange}
            name={name}
            required={required}
            disabled={disabled || readOnly}
            {...restProps}
          />
        }
        classes={{
          root: classes.checkboxLabel,
        }}
        label={label}
        labelPlacement={labelPlacement}
      />
      <FormHelperText>{error ? error : helperText}</FormHelperText>
    </FormControl>
  );
};

const TextField = ({ readOnly, InputProps, InputLabelProps, inputProps, ...props }) => {
  const classes = useInputStyles();
  return (
    <MuiTextField
      variant="filled"
      size={'small'}
      InputProps={{
        readOnly: readOnly,
        classes: {
          root: readOnly ? classes.inputRootReadOnly : null,
        },
        ...InputProps,
      }}
      InputLabelProps={{
        shrink: true,
        classes: { root: readOnly ? classes.inputLabelRootReadOnly : null },
        ...InputLabelProps,
      }}
      inputProps={{
        style: readOnly ? { cursor: 'default' } : {},
      }}
      {...props}
    />
  );
};

const TextFieldSelect = ({ options, ...restProps }) => {
  return (
    <TextField select={true} {...restProps}>
      {options.map((o) => (
        <MenuItem key={o.value} value={o.value}>
          {o.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

TextField.Select = TextFieldSelect;
TextField.Checkbox = TextFieldCheckbox;
export default TextField;

import fetch2, { METHOD } from '../Utils/fetch';
import getConfig from '../Utils/config';

const { apiUrl } = getConfig() || {};

export const fetchUsersWithRole = () => {
  return fetch2(`${apiUrl}/backoffice/users/usersWithRole`, null, METHOD.GET);
};

export const fetchSetUserRole = (email, role) => {
  return fetch2(`${apiUrl}/backoffice/users/setRole`, null, METHOD.POST, { email, role });
};

export const fetchUnsetUserRole = (userId) => {
  return fetch2(`${apiUrl}/backoffice/users/${userId}/unsetRole`, null, METHOD.POST);
};

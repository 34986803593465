import React from 'react';

import Box from '@material-ui/core/Box';

import { FormikTextField } from '../../../Components/Base';

const FormikFieldList = ({ fields, readOnly, boxProps }) => {
  return fields.map(
    ({ label, fieldName, helperText, required, validationSchema, ...restProps }) => (
      <Box mb={3} key={label} {...boxProps}>
        <FormikTextField
          name={fieldName}
          fullWidth={true}
          label={label}
          helperText={helperText || ' '} // space string to always enable helperText component and make the height of TextField always equal to each other.
          required={required}
          readOnly={readOnly}
          {...restProps}
        />
      </Box>
    )
  );
};
FormikFieldList.defaultProps = {
  fields: [],
};

export default FormikFieldList;

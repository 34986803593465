import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import MuiCard from '@material-ui/core/Card';
import MuiCardContent from '@material-ui/core/CardContent';
import MuiCardHeader from '@material-ui/core/CardHeader';

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: theme.shadows[3],
  },
  cardHeader: {
    padding: theme.spacing(4),
  },
  cardHeaderTitle: {},
  cardContent: {
    padding: theme.spacing(2, 4),
  },
  cardContentNoPad: {
    padding: '0px !important',
  },
}));

const Card = (props) => {
  const classes = useStyles();
  return (
    <MuiCard
      classes={{
        root: classes.card,
      }}
      {...props}
    />
  );
};

const CardHeader = (props) => {
  const classes = useStyles();
  return (
    <MuiCardHeader
      classes={{
        root: classes.cardHeader,
        action: classes.cardHeaderAction,
      }}
      {...props}
    />
  );
};

const CardContent = ({ noPadding, ...restProps }) => {
  const classes = useStyles();
  return (
    <MuiCardContent
      classes={{
        root: noPadding ? classes.cardContentNoPad : classes.cardContent,
      }}
      {...restProps}
    />
  );
};

Card.Header = CardHeader;
Card.Content = CardContent;
export default Card;

import _ from 'lodash';
import log from 'loglevel';

let config = null;

const defaultConfig = {
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || 'AIzaSyAsh3AW9C3Nn4DCIXQfOl78HWL-X91oKh0',
    authDomain:
      process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || 'skooldio-assessment-staging.firebaseapp.com',
    databaseURL:
      process.env.REACT_APP_FIREBASE_DATABASE_URL ||
      'https://skooldio-assessment-staging.firebaseio.com',
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || 'skooldio-assessment-staging',
    storageBucket:
      process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || 'skooldio-assessment-staging.appspot.com',
    appId: process.env.REACT_APP_FIREBASE_APP_ID || '1:242735535385:web:2fccecdf3ce94c42',
    messagingSenderId: process.env.REACT_APP_FIREBASE_FCM_SENDER_ID || '242735535385',
    measurementId: process.env.REACT_APP_FIREBASE_G_MEASUREMENT_ID || 'G-ZN44YEH2WR',
  },
  userApi: process.env.REACT_APP_USER_API || 'https://api.soylatte.skooldio.com/user/api',
  apiUrl:
    process.env.REACT_APP_API_URL ||
    'https://asia-east2-skooldio-assessment-staging.cloudfunctions.net',
  rowyUrl:
    process.env.REACT_APP_ROWY_URL || 'https://skooldio-assessment-rowy-staging.web.app/tables'
};

/**
 * @typedef AppConfig
 * @property {Object} firebaseConfig Firebase Config
 * @property {String} userApi Skooldio User API URL
 * @property {String} apiUrl Base URL of Assessments API
 * @property {String} rowyUrl Base URL of Rowy
 */

/**
 * @returns {AppConfig}
 */
function getConfig() {
  if (config === null) {
    config = _.defaultsDeep({}, window.appConfig, defaultConfig);
    log.debug(config);
  }
  return config;
}

export default getConfig;

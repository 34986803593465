import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import { Loading } from '../../../../Components/Base';
import FormikFieldList from '../FormikFieldList';
import { bannerFields as fields } from '../utils';

const leftFields = [fields.title, fields.imageUrl, fields.redirectUrl];
const rightFields = [fields.description];
const bottomFields = [fields.conditionType, fields.conditionScore];

const validationSchema = Yup.object().shape({
  title: fields.title.validationSchema,
  description: fields.description.validationSchema,
  imageUrl: fields.imageUrl.validationSchema,
  redirectUrl: fields.redirectUrl.validationSchema,
  condition: Yup.object().shape({
    type: fields.conditionType.validationSchema,
    metadata: Yup.object().shape({
      score: fields.conditionScore.validationSchema,
    }),
  }),
});

const BannerDetailFormikWrapper = ({ banner, onSubmit, readOnly, children }) => {
  const { title, description, imageUrl, redirectUrl, condition } = banner || {};
  const { type, metadata } = condition || {};
  const { score } = metadata || {};
  return (
    <Formik
      initialValues={{
        title,
        description,
        imageUrl,
        redirectUrl,
        condition: {
          type,
          metadata: { score },
        },
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={async (values, actions) => {
        if (readOnly) {
          return;
        }
        await onSubmit(values);
        actions.resetForm();
      }}
    >
      {(formikProps) => (
        <Form>
          {children(formikProps)}
          <Loading.Modal open={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

const BannerDetailForm = ({ readOnly }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FormikFieldList fields={leftFields} readOnly={readOnly} />
      </Grid>
      <Grid item xs={6}>
        <FormikFieldList fields={rightFields} readOnly={readOnly} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={6}>
        <Typography variant={'subtitle1'}>Banner นี้จะถูกแสดงเมื่อ</Typography>
        <Box display={'flex'} mx={-2} mt={1}>
          <FormikFieldList
            fields={bottomFields}
            readOnly={readOnly}
            boxProps={{ px: 2, mb: 0, width: '100%' }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export { BannerDetailForm, BannerDetailFormikWrapper };
export default BannerDetailForm;

import React from 'react';
import PropTypes from 'prop-types';
import { Link, generatePath } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import _sortBy from 'lodash/sortBy';
import Button from '@material-ui/core/Button';
import { DataGrid } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';

import { FormikTextField, Container, Card } from '../../Components/Base';
import AlertModal from '../../Components/AlertModal';
import routes from '../Main/routes';

const columns = [
  { field: 'id', headerName: 'Assessment ID', flex: 1 },
  { field: 'name', headerName: 'Name', flex: 1 },
  {
    field: 'totalQuestions',
    headerName: 'Total Questions',
    width: 150,
  },
  {
    field: 'questionCount',
    headerName: 'Available Questions',
    width: 165,
  },
  { field: 'timeLimit', headerName: 'Time Limit (sec)', width: 150 },
  {
    field: 'published',
    headerName: 'Published',
    valueFormatter: ({ value }) => value.toString().toUpperCase(),
    width: 150,
  },
  {
    field: '',
    headerName: 'Actions',
    sortable: false,
    width: 120,
    renderCell: (params) => {
      return (
        <Button
          component={Link}
          variant="contained"
          color="primary"
          size="small"
          style={{ margin: '0 auto' }}
          to={generatePath(routes.ASSESSMENT_DETAIL.path, { assessmentId: params.getValue('id') })}
        >
          SEE DETAIL
        </Button>
      );
    },
  },
];

const CreateNewAssessmentModal = ({ onConfirm }) => {
  return (
    <Formik
      initialValues={{ assessmentId: '' }}
      validationSchema={Yup.object().shape({
        assessmentId: Yup.string().required('AssessmentId is required.'),
      })}
      validateOnBlur={true}
      validateOnMount={true}
      onSubmit={async ({ assessmentId }) => {
        await onConfirm(assessmentId);
      }}
    >
      {(formikProps) => {
        return (
          <Form>
            <AlertModal
              title={'Create New Assessment'}
              onConfirm={async () => {
                await formikProps.handleSubmit();
              }}
              cancelButtonProps={{ disabled: formikProps.isSubmitting }}
              confirmButtonProps={{ disabled: !formikProps.isValid || formikProps.isSubmitting }}
              content={
                <FormikTextField
                  name={'assessmentId'}
                  label={'Assessment ID'}
                  fullWidth={true}
                  disabled={formikProps.isSubmitting}
                />
              }
              triggerComponent={(props) => (
                <Button variant={'outlined'} color={'secondary'} startIcon={<AddIcon />} {...props}>
                  ADD NEW ASSESSMENT
                </Button>
              )}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

const Assessments = ({ assessments, onCreateNewAssessment }) => {
  const sortedAssessments = _sortBy(assessments, 'published').reverse();
  return (
    <Container>
      <Card style={{ paddingBottom: '0px' }}>
        <Card.Header
          title={'Assessment List'}
          action={<CreateNewAssessmentModal onConfirm={onCreateNewAssessment} />}
        />

        <Card.Content noPadding>
          <DataGrid
            rows={sortedAssessments}
            columns={columns}
            pageSize={20}
            disableColumnMenu={true}
            autoHeight={true}
          />
        </Card.Content>
      </Card>
    </Container>
  );
};

Assessments.propTypes = {
  assessments: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      totalQuestions: PropTypes.number,
      questionCount: PropTypes.number,
      timeLimit: PropTypes.number,
      published: PropTypes.bool,
      id: PropTypes.string,
    })
  ),
};

Assessments.defaultProps = {
  assessments: [],
};
export default Assessments;

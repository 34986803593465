import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { SubtopicDetailFormikWrapper, SubtopicDetailForm } from './SubtopicDetailForm';

const SubtopicDetailFormModal = ({
  subtopic,
  title,
  confirmButtonText,
  cancelButtonText,
  open,
  onClose,
  onSubmit,
}) => {
  const handleSubmit = async (values) => {
    try {
      await onSubmit(values);
      onClose();
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <SubtopicDetailFormikWrapper subtopic={subtopic} onSubmit={handleSubmit}>
      {(formikProps) => (
        <Dialog
          open={open}
          disableBackdropClick
          onClose={onClose}
          fullWidth={true}
          maxWidth={'md'}
          scroll={'paper'}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent dividers>
            <SubtopicDetailForm />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                formikProps.resetForm();
                onClose();
              }}
              disabled={formikProps.isSubmitting}
              type={'button'}
            >
              {cancelButtonText}
            </Button>
            <Button
              type={'submit'}
              onClick={formikProps.handleSubmit}
              color="primary"
              variant={'contained'}
              disabled={formikProps.isSubmitting || !formikProps.dirty || !formikProps.isValid}
            >
              {confirmButtonText}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </SubtopicDetailFormikWrapper>
  );
};
SubtopicDetailFormModal.defaultProps = {
  title: 'แก้ไขข้อมูล Subtopic',
  confirmButtonText: 'ยืนยันการแก้ไข',
  cancelButtonText: 'ยกเลิก',
};

export default SubtopicDetailFormModal;

import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import Button from '@material-ui/core/Button';

import { Card, Loading } from '../../../Components/Base';

const COLUMNS = [
  { field: 'id', headerName: 'Question ID', width: 270 },
  { field: 'subtopic', headerName: 'Subtopic', flex: 1 },
  {
    field: 'required',
    headerName: 'Required',
    width: 180,
  },
  { field: 'requiredAtIndex', headerName: 'Required At Index', width: 180 },
];

const QuestionListTable = ({ questions, onGetQuestionCsv }) => {
  const [loading, setLoading] = React.useState(false);
  const handleGetQuestionCsv = async () => {
    setLoading(true);
    try {
      await onGetQuestionCsv();
    } catch (err) {
      alert(err.message);
    }

    setLoading(false);
  };
  return (
    <>
      <Card>
        <Card.Header
          title={`คำถามทั้งหมดในแบบทดสอบ`}
          mb={3}
          action={
            <Button onClick={handleGetQuestionCsv} variant={'outlined'} color={'secondary'}>
              Download Question CSV
            </Button>
          }
        />
        <Card.Content noPadding>
          <DataGrid
            autoHeight={true}
            rows={questions}
            columns={COLUMNS.map((column) => ({
              ...column,
              disableClickEventBubbling: true,
            }))}
            pageSize={100}
            disableColumnMenu={true}
          />
        </Card.Content>
      </Card>
      <Loading.Modal open={loading} />
    </>
  );
};

export default QuestionListTable;

import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import EditIcon from '@material-ui/icons/Edit';

import useModal from '../../../hooks/useModal';
import { Card, Loading } from '../../../Components/Base';

import FormikFieldList from './FormikFieldList';
import { assessmentDetailFields as fields } from './utils';

const leftFields = [fields.fullScore, fields.timeLimit, fields.type];
const rightFields = [fields.topic, fields.totalQuestions, fields.showNotKnowChoice];

const validationSchema = Yup.object().shape({
  fullScore: fields.fullScore.validationSchema,
  timeLimit: fields.timeLimit.validationSchema,
  type: fields.type.validationSchema,
  topic: fields.topic.validationSchema,
  totalQuestions: fields.totalQuestions.validationSchema,
  metadata: Yup.object().shape({ showNotKnowChoice: fields.showNotKnowChoice.validationSchema }),
});

const AssessmentDataFormikWrapper = ({ assessment, onSubmit, readOnly, children }) => {
  const { fullScore, timeLimit, type, topic, totalQuestions, metadata } = assessment || {};
  const { showNotKnowChoice } = metadata || {};
  return (
    <Formik
      initialValues={{
        fullScore,
        timeLimit,
        type,
        topic,
        totalQuestions,
        metadata: { showNotKnowChoice },
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={async (values) => {
        if (readOnly) {
          return;
        }
        await onSubmit(values);
      }}
    >
      {(formikProps) => {
        return (
          <Form>
            {children(formikProps)}
            <Loading.Modal open={formikProps.isSubmitting} />
          </Form>
        );
      }}
    </Formik>
  );
};

const AssessmentDataForm = ({ readOnly }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FormikFieldList fields={leftFields} readOnly={readOnly} />
      </Grid>
      <Grid item xs={6}>
        <FormikFieldList fields={rightFields} readOnly={readOnly} />
      </Grid>
    </Grid>
  );
};

const EditAssessmentDataModal = ({ assessment, open, onClose, onSubmit }) => {
  const handleSubmit = async (values) => {
    try {
      await onSubmit(values);
      onClose();
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <AssessmentDataFormikWrapper assessment={assessment} onSubmit={handleSubmit}>
      {(formikProps) => (
        <Dialog
          open={open}
          disableBackdropClick
          onClose={onClose}
          fullWidth={true}
          maxWidth={'md'}
          scroll={'paper'}
        >
          <DialogTitle>แก้ไขข้อมูลแบบทดสอบ</DialogTitle>
          <DialogContent dividers>
            <AssessmentDataForm />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                formikProps.resetForm();
                onClose();
              }}
              disabled={formikProps.isSubmitting}
              type={'button'}
            >
              ยกเลิก
            </Button>
            <Button
              type={'submit'}
              onClick={formikProps.handleSubmit}
              color="primary"
              variant={'contained'}
              disabled={formikProps.isSubmitting || !formikProps.dirty || !formikProps.isValid}
            >
              ยืนยันการแก้ไข
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </AssessmentDataFormikWrapper>
  );
};

const AssessmentData = ({ data, onUpdate }) => {
  const { modalOpen, handleModalOpen, handleModalClose } = useModal();
  return (
    <>
      <Card>
        <Card.Header
          title={'ข้อมูลแบบทดสอบ'}
          action={
            <Button
              aria-label="edit"
              color={'secondary'}
              startIcon={<EditIcon />}
              onClick={handleModalOpen}
            >
              แก้ไข
            </Button>
          }
        />
        <Card.Content>
          <AssessmentDataFormikWrapper assessment={data} readOnly={true}>
            {(formikProps) => <AssessmentDataForm readOnly={true} />}
          </AssessmentDataFormikWrapper>
        </Card.Content>
      </Card>
      <EditAssessmentDataModal
        assessment={data}
        onSubmit={onUpdate}
        open={modalOpen}
        onClose={handleModalClose}
      />
    </>
  );
};

export default AssessmentData;

import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Alert, AlertTitle } from '@material-ui/lab';

import { FormikTextField, Loading, Card, TextField } from '../../../Components/Base';
import AlertModal from '../../../Components/AlertModal';
import FormikFieldList from './FormikFieldList';

const FIELDS = [
  {
    name: 'deleteExistingOnes',
    label: 'ลบโจทย์เดิมใน Assessment นี้',
    type: 'checkbox',
    helperText:
      'หาก checked โจทย์เดิมทั้งหมดที่อยู่ในแบบทดสอบนี้จะถูกลบหลังจากโจทย์ใหม่ถูกอัปโหลดขึ้นไปแล้ว',
    component: TextField.Checkbox,
  },
  {
    name: 'defaultProgrammingLanguage',
    label: 'DEFAULT PROGRAMMING LANGUAGE',
    helperText:
      'ค่านี้จะถูกนำมาใช้ต่อเมื่อไม่มีการกำหนด Programming language เอาไว้ใน CODE BLOCK ในโจทย์',
  },
  {
    name: 'defaultScorePerQuestion',
    label: 'DEFAULT SCORE PER QUESTION',
    type: 'number',
    helperText: 'ค่านี้จะถูกนำมาใช้เฉพาะกับโจทย์ที่ไม่มีการกำหนด SCORE เอาไว้',
  },
  {
    name: 'html',
    label: 'HTML OF THE QUESTIONS (EXPORTED FROM NOTION)',
    multiline: true,
    rows: 20,
  },
];

const ActionPanel = ({ onReset, onSubmit, assessmentName }) => {
  return (
    <Box display={'flex'} justifyContent={'flex-end'}>
      <Button variant={'text'} color={'inherit'} type={'button'} onClick={onReset}>
        รีเซต
      </Button>
      <Box mr={3} />
      <AlertModal
        title={`อัปโหลดโจทย์`}
        content={`คุณแน่ใจว่าต้องการอัปโหลดโจทย์ให้กับแบบทดสอบ ${assessmentName}`}
        onConfirm={onSubmit}
        triggerComponent={(props) => (
          <Button variant={'contained'} color={'primary'} type={'button'} {...props}>
            อัปโหลดโจทย์
          </Button>
        )}
      />
    </Box>
  );
};

const ImportQuestions = ({ assessment, onImportQuestions }) => {
  return (
    <>
      <Formik
        initialValues={{
          deleteExistingOnes: false,
          defaultProgrammingLanguage: '',
          defaultScorePerQuestion: '15',
          html: '',
        }}
        validationSchema={yup.object().shape({
          deleteExistingOnes: yup.boolean(),
          defaultProgrammingLanguage: yup.string(),
          defaultScorePerQuestion: yup.number(),
          html: yup.string().required(),
        })}
        onSubmit={async (values) => {
          const id = assessment.id;
          const { html, ...restValues } = values;
          try {
            await onImportQuestions(id, html, restValues);
            alert('โจทย์ถูกอัปโหลดสำเร็จ');
          } catch (err) {
            console.error(err);
            alert(err.message);
          }
        }}
      >
        {(formikProps) => {
          return (
            <Form>
              <Card>
                <Card.Header title={'Import Questions'} />
                <Card.Content style={{ paddingTop: 0 }}>
                  <Alert severity="warning">
                    <AlertTitle>ก่อนอัปโหลดโจทย์ใหม่</AlertTitle>
                    กรุณาตรวจสอบว่าคุณได้ทำการบันทึกไฟล์ <strong>CSV</strong> ของโจทย์ปัจจุบันไว้ใน
                    Shared Drive เรียบร้อยแล้ว หากยังไม่ได้บันทึกไฟล์ <strong>CSV</strong>{' '}
                    คุณสามารถดาวน์โหลดไฟล์ใหม่ได้ในแทป <strong>QUESTIONS</strong>
                  </Alert>
                  <Box p={1} />
                  <Alert severity="info">
                    <AlertTitle>หลังอัปโหลดโจทย์ใหม่</AlertTitle>
                    ระบบจะทำการดาวน์โหลดไฟล์ <strong>CSV</strong> ของโจทย์ชุดใหม่ให้โดยอัตโนมัติ
                    กรุณาบันทึกไฟล์ <strong>CSV</strong> ไว้ใน Shared Drive
                    เพื่อนำมาใช้วิเคราะห์ข้อมูลในภายหลัง
                  </Alert>
                </Card.Content>
                <Card.Content>
                  <FormikFieldList fields={FIELDS} />
                </Card.Content>

                <Card.Content>
                  <ActionPanel
                    onReset={formikProps.resetForm}
                    onSubmit={formikProps.submitForm}
                    assessmentName={assessment.name}
                  />
                </Card.Content>
              </Card>

              <Loading.Modal open={formikProps.isSubmitting} />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ImportQuestions;

import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Grid from '@material-ui/core/Grid';

import { Loading } from '../../../../Components/Base';
import FormikFieldList from '../FormikFieldList';
import { subtopicFields as fields } from '../utils';

const leftFields = [fields.name];
const rightFields = [fields.totalQuestionsBySubtopic];

const validationSchema = Yup.object().shape({
  name: fields.name.validationSchema,
  totalQuestionsBySubtopic: fields.totalQuestionsBySubtopic.validationSchema,
});

const SubtopicDetailFormikWrapper = ({ subtopic, onSubmit, readOnly, children }) => {
  const { name, totalQuestionsBySubtopic } = subtopic;
  return (
    <Formik
      initialValues={{
        name,
        totalQuestionsBySubtopic,
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={async (values, actions) => {
        if (readOnly) {
          return;
        }
        await onSubmit(values);
        actions.resetForm();
      }}
    >
      {(formikProps) => (
        <Form>
          {children(formikProps)}
          <Loading.Modal open={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

const SubtopicDetailForm = ({ readOnly }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FormikFieldList fields={leftFields} readOnly={readOnly} />
      </Grid>
      <Grid item xs={6}>
        <FormikFieldList fields={rightFields} readOnly={readOnly} />
      </Grid>
    </Grid>
  );
};

export { SubtopicDetailForm, SubtopicDetailFormikWrapper };
export default SubtopicDetailForm;

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { renderRoutes, unAuthenticatedRoutes } from './routes';
import AppLayout from './Components/AppLayout';
import BlankLayout from './Components/BlankLayout';

const RouteApp = () => {
  return (
    <Router>
      <Switch>
        {renderRoutes.map(({ path, exact, component: Component }) => {
          return (
            <Route key={path} path={path} exact={exact}>
              <AppLayout>
                <Component />
              </AppLayout>
            </Route>
          );
        })}

        {unAuthenticatedRoutes.map(({ path, exact, component: Component }) => {
          return (
            <Route key={path} path={path} exact={exact}>
              <BlankLayout>
                <Component />
              </BlankLayout>
            </Route>
          );
        })}
      </Switch>
    </Router>
  );
};

export default RouteApp;

import React from 'react';
import Box from '@material-ui/core/Box';

import { Card } from '../../../../Components/Base';

import EditOrderOfSubtopicsModal from './EditOrderOfSubtopicsModal';
import AddNewSubtopicModal from './AddNewSubtopicModal';
import SubtopicAccordion from './SubtopicAccordion';

const Subtopics = ({
  subtopics,
  onSubtopicAdd,
  onSubtopicUpdate,
  onSubtopicDelete,
  onSubtopicOrderUpdate,
}) => {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleAccordionExpand = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Card style={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>
        <Card.Header
          title={'Subtopics'}
          action={
            <EditOrderOfSubtopicsModal
              onUpdate={onSubtopicOrderUpdate}
              subtopics={subtopics}
              disabled={subtopics.length <= 0}
            />
          }
        />
      </Card>
      <Box mb={'1px'} />
      {subtopics.map((subtopic, index) => {
        const { name } = subtopic;

        const panelId = `panel-${index}-${name}`;
        return (
          <SubtopicAccordion
            expanded={expanded === panelId}
            onDelete={async () => {
              await onSubtopicDelete(name);
            }}
            subtopic={subtopic}
            onUpdate={(values) => onSubtopicUpdate(name, values)}
            onClickAccordion={handleAccordionExpand(panelId)}
            key={panelId}
          />
        );
      })}
      <Box mt={3}>
        <AddNewSubtopicModal onConfirm={onSubtopicAdd} />
      </Box>
    </>
  );
};

Subtopics.defaultProps = {
  subtopics: [],
};

export default Subtopics;

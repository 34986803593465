import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';

import { LOGO_BAR_HEIGHT } from './constants';

const useStyles = makeStyles((theme) => ({
  logoBar: {
    zIndex: theme.zIndex.drawer + 2,
    backgroundColor: 'white',
    width: '100%',
    height: LOGO_BAR_HEIGHT,
    justifyContent: 'center',
    paddingLeft: theme.spacing(2),
  },
}));

export default function AppLayout({ children }) {
  const classes = useStyles();

  return (
    <AppBar classes={{ root: classes.logoBar }}>
      <img src="/assets/images/logo.png" alt={'Skill Score Logo'} width={'210px'} />
    </AppBar>
  );
}

import getConfig from '../Utils/config';
import fetch2, { METHOD } from '../Utils/fetch';

const { apiUrl } = getConfig() || {};

export const fetchAssessments = async () => {
  return fetch2(`${apiUrl}/backoffice/assessments`);
};

export const fetchAssessmentById = async (assessmentId) => {
  return fetch2(`${apiUrl}/backoffice/assessments/${assessmentId}`);
};

export const fetchCreateAssessment = async (newAssessmentId) => {
  return fetch2(`${apiUrl}/backoffice/assessments`, null, METHOD.POST, { id: newAssessmentId });
};

export const fetchUpdateAssessment = async (assessmentId, updatedValues) => {
  return fetch2(
    `${apiUrl}/backoffice/assessments/${assessmentId}`,
    null,
    METHOD.PATCH,
    updatedValues
  );
};

export const fetchDeleteAssessment = async (assessmentId) => {
  return fetch2(`${apiUrl}/backoffice/assessments/${assessmentId}`, null, METHOD.DELETE);
};

export const fetchCreateAssessmentSubtopic = async (assessmentId, subtopicName) => {
  return fetch2(`${apiUrl}/backoffice/assessments/${assessmentId}/addSubtopic`, null, METHOD.POST, {
    name: subtopicName,
  });
};

export const fetchUpdateAssessmentSubtopic = async (
  assessmentId,
  currentSubtopicName,
  updateValues
) => {
  const { name, totalQuestionsBySubtopic } = updateValues;

  return fetch2(
    `${apiUrl}/backoffice/assessments/${assessmentId}/updateSubtopic`,
    null,
    METHOD.PATCH,
    {
      name: currentSubtopicName,
      updateValues: {
        name,
        totalQuestionsBySubtopic,
      },
    }
  );
};

export const fetchDeleteAssessmentSubtopic = async (assessmentId, subtopicName) => {
  return fetch2(
    `${apiUrl}/backoffice/assessments/${assessmentId}/deleteSubtopic`,
    null,
    METHOD.DELETE,
    {
      name: subtopicName,
    }
  );
};

export const fetchPublishAssessment = async (assessmentId) => {
  return fetch2(`${apiUrl}/backoffice/assessments/${assessmentId}/publish`, null, METHOD.POST);
};

export const fetchUnpublishAssessment = async (assessmentId) => {
  return fetch2(`${apiUrl}/backoffice/assessments/${assessmentId}/unpublish`, null, METHOD.POST);
};

/**
 * fetchImportQuestions will call importQuestions api which will return a csv of the questions as a response.
 * Then, automatically download the csv as questions.csv file.
 *
 * Possible params
 * - deleteExistingOnes
 * - defaultProgrammingLanguage
 * - assetSourceFolder
 * - defaultScorePerQuestion
 */
export const fetchImportQuestions = async (assessmentId, html, params) => {
  const queryParams = {};
  Object.keys(params).forEach((paramKey) => {
    const value = params[paramKey];
    const stringValue = value ? value.toString() : null;
    if (stringValue && stringValue !== '') {
      queryParams[paramKey] = stringValue;
    }
  });

  try {
    // Import Questions
    const csvResp = await fetch2(
      `${apiUrl}/backoffice/assessments/${assessmentId}/importQuestions`,
      queryParams,
      METHOD.POST,
      html,
      { parseJson: false, contentType: 'text/html', praseBody: false }
    );

    // Create csv file from csvResp
    var blob = new Blob([csvResp], { type: 'application/octet-stream' });

    // Download the csv file by programmatically create link element.
    // This method does not support IE browser.
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'questions.csv';
    link.click();
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const fetchDownloadQuestionCsv = async (assessmentId) => {
  try {
    // Get CSV
    const csvResp = await fetch2(
      `${apiUrl}/backoffice/assessments/${assessmentId}/generateQuestionCsv`,
      null,
      METHOD.GET,
      null,
      { parseJson: false }
    );

    // Create csv file from csvResp
    var blob = new Blob([csvResp], { type: 'application/octet-stream' });

    // Download the csv file by programmatically create link element.
    // This method does not support IE browser.
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'questions.csv';
    link.click();
  } catch (err) {
    console.log(err);
    throw err;
  }
};

import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import SwapVertIcon from '@material-ui/icons/SwapVert';

import useModal from '../../../../hooks/useModal';
import { FormikArrayTextField, Loading } from '../../../../Components/Base';

const EditOrderOfSubtopicsModal = ({ banners, onUpdate, disabled }) => {
  const { modalOpen, handleModalOpen, handleModalClose } = useModal();
  const values = banners.map((b) => b.title);
  return (
    <>
      <Formik
        initialValues={{ banners: values }}
        onSubmit={async (values) => {
          await onUpdate(values);
          handleModalClose();
        }}
        enableReinitialize={true}
        validateOnChange={true}
        validationSchema={Yup.object().shape({
          banners: Yup.array().of(Yup.string()),
        })}
      >
        {(formikProps) => {
          return (
            <Form>
              <Dialog
                open={modalOpen}
                disableBackdropClick
                onClose={handleModalClose}
                fullWidth={true}
                maxWidth={'sm'}
              >
                <DialogTitle>แก้ไขลำดับ Banners</DialogTitle>
                <DialogContent dividers>
                  <FormikArrayTextField
                    readOnly={true}
                    disabled={true}
                    fieldName={'banners'}
                    values={formikProps.values}
                    disableAddRemove={true}
                    disableReorder={false}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      formikProps.resetForm();
                      handleModalClose();
                    }}
                    disabled={formikProps.isSubmitting}
                    type={'button'}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type={'submit'}
                    onClick={formikProps.handleSubmit}
                    color="primary"
                    variant={'contained'}
                    disabled={formikProps.isSubmitting || !formikProps.dirty}
                  >
                    ยืนยันการแก้ไข
                  </Button>
                </DialogActions>
                <Loading.Modal open={formikProps.isSubmitting} />
              </Dialog>
            </Form>
          );
        }}
      </Formik>
      <Button
        color={'secondary'}
        onClick={handleModalOpen}
        startIcon={<SwapVertIcon />}
        disabled={disabled}
      >
        แก้ไขลำดับ BANNERS
      </Button>
    </>
  );
};

export default EditOrderOfSubtopicsModal;

import React from 'react';
import { useHistory, generatePath } from 'react-router-dom';

import { Loading } from '../../Components/Base';
import { fetchAssessments, fetchCreateAssessment } from '../../apis/assessment';
import useModal from '../../hooks/useModal';
import routes from '../Main/routes';

import AssessmentsView from './Assessments.view';

const formatAssessments = (assessments) => {
  return assessments.map((assessment) => {
    const { name, timeLimit, totalQuestions, id, metadata, published } = assessment;
    const { questions } = metadata || {};
    let questionCount = 0;

    if (!questions) {
    } else if (Array.isArray(questions)) {
      questionCount = questions.length;
    } else {
      for (const subtopic in questions) {
        const questionsInSubtopic = questions[subtopic];
        questionCount = questionCount + questionsInSubtopic.length;
      }
    }

    return {
      name,
      timeLimit,
      totalQuestions,
      id,
      questionCount,
      published,
    };
  });
};

const Assessments = () => {
  const history = useHistory();
  const { modalOpen, handleModalOpen, handleModalClose } = useModal();

  const [assessments, setAssessments] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const getData = async () => {
      try {
        const { assessments } = await fetchAssessments();
        const formattedAssessments = formatAssessments(assessments);
        setAssessments(formattedAssessments);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  const handleCreateNewAssessment = async (assessmentId) => {
    handleModalOpen();
    try {
      const res = await fetchCreateAssessment(assessmentId);
      history.push(generatePath(routes.ASSESSMENT_DETAIL.path, { assessmentId }));
      return res;
    } catch (err) {
      console.error(err);
      alert(err.message);
    } finally {
      handleModalClose();
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <AssessmentsView
        assessments={assessments}
        onCreateNewAssessment={handleCreateNewAssessment}
      />
      <Loading.Modal open={modalOpen} />
    </>
  );
};

export default Assessments;

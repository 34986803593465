import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import { Alert, AlertTitle } from '@material-ui/lab';

import useModal from '../../../hooks/useModal';
import { TextField, Card, FormikArrayTextField, Loading } from '../../../Components/Base';

import { assessmentDetailFields as fields } from './utils';

const EditDisplaySubtopicsModal = ({ values, onUpdate, onClose, open }) => {
  const fieldName = fields.displaySubtopics.fieldName;
  return (
    <Formik
      initialValues={{ [fieldName]: values }}
      onSubmit={async (values) => {
        await onUpdate(values);
        onClose();
      }}
      enableReinitialize={true}
      validateOnChange={true}
      validationSchema={Yup.object().shape({
        [fieldName]: fields.displaySubtopics.validationSchema,
      })}
    >
      {(formikProps) => {
        return (
          <Form>
            <Dialog
              open={open}
              disableBackdropClick
              onClose={onClose}
              fullWidth={true}
              maxWidth={'sm'}
            >
              <DialogTitle>แก้ไข Display Subtoipcs</DialogTitle>
              <DialogContent dividers>
                <FormikArrayTextField
                  readOnly={false}
                  disabled={false}
                  fieldName={fieldName}
                  values={formikProps.values}
                  disableAddRemove={false}
                  disableReorder={false}
                  addItemText={'เพิ่ม Display Subtoipcs'}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    formikProps.resetForm();
                    onClose();
                  }}
                  disabled={formikProps.isSubmitting}
                  type={'button'}
                >
                  ยกเลิก
                </Button>
                <Button
                  type={'submit'}
                  onClick={formikProps.handleSubmit}
                  color="primary"
                  variant={'contained'}
                  disabled={formikProps.isSubmitting || !formikProps.dirty}
                >
                  ยืนยันการแก้ไข
                </Button>
              </DialogActions>
              <Loading.Modal open={formikProps.isSubmitting} />
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
};

const DisplaySubtopics = ({ textFieldProps, onUpdate, displaySubtopics }) => {
  const { modalOpen, handleModalOpen, handleModalClose } = useModal();

  return (
    <Card>
      <Card.Header
        title={'ข้อมูล Display Subtopics'}
        action={
          <Button
            aria-label="edit"
            color={'secondary'}
            startIcon={<EditIcon />}
            onClick={handleModalOpen}
          >
            แก้ไข
          </Button>
        }
      />
      <Card.Content style={{ paddingTop: 0 }}>
        <Alert severity="info">
          <AlertTitle>Info</AlertTitle>
          ข้อมูลนี้จะถูกแสดงผลเป็นลิสต์ ในส่วน <strong>“แบบทดสอบนี้ครอบคลุมเนื้อหา”</strong> ในหน้า
          Cover ของแบบทดสอบ หากไม่กำหนด ระบบจะนำ “Subtopics” มาแสดงแทน
        </Alert>
      </Card.Content>

      <Card.Content>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {displaySubtopics.length > 0 ? (
              displaySubtopics.map((value) => {
                return (
                  <Box mb={3} key={value}>
                    <TextField
                      value={value}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      readOnly={true}
                      fullWidth={true}
                      {...textFieldProps}
                    />
                  </Box>
                );
              })
            ) : (
              <Typography variant={'subtitle1'}>
                ยังไม่มีข้อมูล Display Subtopics คลิกปุ่ม <strong>"แก้ไข"</strong> เพื่อเพิ่มข้อมูล
              </Typography>
            )}
            <EditDisplaySubtopicsModal
              open={modalOpen}
              values={displaySubtopics}
              onUpdate={onUpdate}
              onClose={handleModalClose}
            />
          </Grid>
        </Grid>
      </Card.Content>
    </Card>
  );
};

DisplaySubtopics.defaultProps = {
  displaySubtopics: [],
  onUpdate: () => {},
};

export default DisplaySubtopics;

/**
 * This file is derived from /web/src/Utils/fetch
 */
import log from 'loglevel';

const resolveArgument = (value) => (typeof value === 'string' ? value : JSON.stringify(value));

export const argsToString = (args = {}) => {
  const keys = Object.keys(args);
  if (keys.length === 0) return '';
  return '?' + keys.map((key) => `${key}=${resolveArgument(args[key])}`).join('&');
};

const handleErrors = async (res, path) => {
  if (res.ok) return res;

  let error = new Error('Unknown Error');
  const respJson = await res.json();
  error.statusCode = respJson.error.statusCode || res.status;
  error = new Error(respJson.error.message);
  error.name = respJson.error.name;
  error.details = respJson.error.details;
  error.response = res;
  throw error;
};

const parseValue = (res, parseAsJson = true) => {
  if (res.status === 204) return {};
  return parseAsJson ? res.json() : res.text();
};

function fetch2(
  path,
  params = null,
  method = METHOD.GET,
  body,
  fetchOptions = { parseJson: true, contentType: 'application/json', praseBody: true }
) {
  const { contentType, praseBody } = fetchOptions;

  const options = {
    method,
    headers: Object.assign(
      {},
      {
        Accept: 'application/json, text/plain,text/html, */*',
        'Content-Type': contentType,
      }
    ),
    credentials: 'include',
  };
  if (body) {
    options.body = options.body = praseBody ? JSON.stringify(body) : body;
  }

  const callPath = params ? `${path}${argsToString(params)}` : path;

  log.debug('url is ', method, callPath, body);

  return fetch(callPath, options)
    .then((res) => handleErrors(res, callPath))
    .then((res) => parseValue(res, fetchOptions.parseJson));
}

export const METHOD = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export default fetch2;

import React from 'react';
import { Link as RouterLink, useRouteMatch, generatePath } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';

import { menuRoutes, appSettingsMenuRoutes } from '../routes';

import { NAVBAR_WIDTH, LOGO_BAR_HEIGHT } from './constants';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: NAVBAR_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    paddingTop: LOGO_BAR_HEIGHT,
    width: NAVBAR_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    paddingTop: LOGO_BAR_HEIGHT,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
}));

const MenuListItem = ({ route, index }) => {
  const { icon: Icon, name, path } = route;

  const match = useRouteMatch({ path, exact: true });
  return (
    <ListItem button component={RouterLink} to={generatePath(path)} selected={Boolean(match)}>
      {Icon && (
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
      )}

      <ListItemText primary={name} />
    </ListItem>
  );
};

const HeaderMenuList = ({ onClickArrow }) => {
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'flex-end'}
      style={{ height: LOGO_BAR_HEIGHT }}
    >
      <IconButton onClick={onClickArrow}>
        <ChevronLeftIcon />
      </IconButton>
    </Box>
  );
};

const MenuList = () => {
  return (
    <>
      <List>
        {menuRoutes.map((route, index) => {
          return <MenuListItem key={route.name} route={route} index={index} />;
        })}
      </List>
      <Box mt={'auto'} />
      <List>
        {appSettingsMenuRoutes.map((route, index) => {
          return <MenuListItem key={route.name} route={route} index={index} />;
        })}
      </List>
    </>
  );
};

const AuthenticatedUserMenu = ({ userInfo, onLogout }) => {
  return (
    <List>
      <ListItem>
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        <ListItemText primary={userInfo && userInfo.email} />
      </ListItem>
      <ListItem button onClick={onLogout}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary={'Logout'} />
      </ListItem>
    </List>
  );
};

const NavBar = ({ open, onClose, onLogout, userInfo, isLogin }) => {
  const classes = useStyles();
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <HeaderMenuList onClickArrow={onClose} />

      <Divider />

      <MenuList />

      <Divider />

      {isLogin && <AuthenticatedUserMenu userInfo={userInfo} onLogout={onLogout} />}
    </Drawer>
  );
};
export default NavBar;

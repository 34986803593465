import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import useModal from '../hooks/useModal';

function AlertModalView({
  title,
  content,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  onClose,
  open,
  dialogProps,
  cancelButtonProps,
  confirmButtonProps,
}) {
  const handleCancel = async () => {
    await onCancel();
    onClose();
  };
  const handleConfirm = async () => {
    await onConfirm();
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth={'sm'} fullWidth={true} {...dialogProps}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary" {...cancelButtonProps} type={'button'}>
          {cancelText || 'Cancel'}
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          autoFocus
          {...confirmButtonProps}
          type={'button'}
        >
          {confirmText || 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const AlertModalWithoutTriggerComponent = (props) => {
  return <AlertModalView {...props} />;
};

const AlertModalWithTriggerComponent = ({
  triggerComponent: TriggerComponent,
  open,
  onOpen,
  onClose,
  ...restProps
}) => {
  const { modalOpen, handleModalOpen, handleModalClose } = useModal();
  const handleOpen = () => {
    handleModalOpen();
    onOpen();
  };

  const handleClose = () => {
    handleModalClose();
    onClose();
  };
  return (
    <>
      <TriggerComponent onClick={handleOpen} />
      <AlertModalView open={modalOpen} onClose={handleClose} {...restProps} />
    </>
  );
};

const AlertModal = ({ triggerComponent, ...restProps }) => {
  return !triggerComponent ? (
    <AlertModalWithoutTriggerComponent {...restProps} />
  ) : (
    <AlertModalWithTriggerComponent triggerComponent={triggerComponent} {...restProps} />
  );
};

AlertModal.propTypes = {
  triggerComponent: PropTypes.any,
  open: PropTypes.bool,
  onOpen: PropTypes.func, // Use this prop only when triggerComponent is set.
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.node,
  confirmText: PropTypes.node,
  cancelText: PropTypes.node,
  cancelButtonProps: PropTypes.object,
  confirmButtonProps: PropTypes.object,
};

AlertModal.defaultProps = {
  onConfirm: () => {},
  onCancel: () => {},
  onOpen: () => {},
  onClose: () => {},
};

export default AlertModal;

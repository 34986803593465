import React from 'react';
import { FieldArray } from 'formik';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import RemoveIcon from '@material-ui/icons/Remove';

import FormikTextField from './FormikTextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const Buttons = ({
  onLeftButtonClick,
  onRightButtonClick,
  leftChildren,
  rightChildren,
  leftProps,
  rightProps,
  disableLeft,
  disableRight,
  ...restProps
}) => (
  <ButtonGroup
    size={'small'}
    color="primary"
    variant={'contained'}
    aria-label="text primary button group"
    {...restProps}
  >
    <Button onClick={onLeftButtonClick} disabled={disableLeft} {...leftProps}>
      {leftChildren}
    </Button>
    <Button onClick={onRightButtonClick} disabled={disableRight} {...rightProps}>
      {rightChildren}
    </Button>
  </ButtonGroup>
);

const FormikFieldArray = ({
  readOnly,
  disabled,
  fieldName,
  values,
  disableAddRemove,
  disableReorder,
  addItemText,
}) => {
  const fieldValues = values[fieldName];
  return (
    <FieldArray
      name={fieldName}
      render={(arrayHelpers) => (
        <>
          {fieldValues && fieldValues.length > 0 ? (
            fieldValues.map((value, index) => (
              <Box mb={3} key={index} display={'flex'} alignItems={'center'}>
                <FormikTextField
                  name={`${fieldName}[${index}]`}
                  readOnly={readOnly}
                  disabled={disabled}
                  fullWidth={true}
                />

                <Box mr={2} />
                {!disableReorder && (
                  <Buttons
                    onLeftButtonClick={() => arrayHelpers.swap(index, index - 1)}
                    onRightButtonClick={() => arrayHelpers.swap(index, index + 1)}
                    leftChildren={<ArrowUpwardIcon />}
                    rightChildren={<ArrowDownwardIcon />}
                    disableLeft={index <= 0}
                    disableRight={index >= fieldValues.length - 1}
                  />
                )}

                {!disableAddRemove && (
                  <>
                    <Box mr={2} />
                    <Buttons
                      onLeftButtonClick={() => arrayHelpers.remove(index)}
                      onRightButtonClick={() => arrayHelpers.insert(index + 1, '')}
                      leftChildren={<RemoveIcon />}
                      rightChildren={<AddIcon />}
                    />
                  </>
                )}
              </Box>
            ))
          ) : (
            <Button type="button" color={'primary'} onClick={() => arrayHelpers.push('')}>
              {addItemText}
            </Button>
          )}
        </>
      )}
    />
  );
};

export default FormikFieldArray;

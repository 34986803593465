import firebase from 'firebase/app';

import fetch2, { METHOD } from '../Utils/fetch';
import getConfig from '../Utils/config';

const { userApi, apiUrl } = getConfig() || {};

export const fetchCurrentSkooldioUserToken = () => {
  return fetch2(`${userApi}/token/me`, null, METHOD.GET);
};

export const fetchCreateCustomToken = async (token) => {
  const response = await fetch(`${apiUrl}/v1/users/token`, {
    method: 'POST',
    headers: {
      'x-auth-token': token,
    },
  });
  return response.json();
};

export const fetchLoginWithSkooldioAccount = ({ email, password }) => {
  return fetch2(`${userApi}/token/authenticate`, null, METHOD.POST, {
    email,
    password,
  });
};

export const fetchLogoutWithSkooldioAccount = () => {
  return fetch2(`${userApi}/users/logout`, null, METHOD.POST);
};

/**
 * - Create custom token with token from skooldio auth.
 * - Sign in with Firebase custom token
 */
export const fetchLoginWithFirebaseAuth = async (authToken) => {
  const { token: firebaseAuthToken } = await fetchCreateCustomToken(authToken);
  return await firebase.auth().signInWithCustomToken(firebaseAuthToken);
};

export const fetchLogoutWithFirebaseAuth = async () => {
  await firebase.auth().signOut();
};

export const fetchSessionLogin = async (idToken) => {
  return await fetch2(`${apiUrl}/backoffice/auth/sessionLogin`, null, METHOD.POST, {
    idToken,
  });
};

export const fetchSessionLogout = async (idToken) => {
  return await fetch2(`${apiUrl}/backoffice/auth/sessionLogout`, null, METHOD.POST);
};

export const fetchCurrentUser = async () => {
  return await fetch2(`${apiUrl}/backoffice/auth/me`);
};

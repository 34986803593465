import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import AlertModal from '../../../../Components/AlertModal';
import { FormikTextField, Loading } from '../../../../Components/Base';

const AddNewSubtopicModal = ({ onConfirm }) => {
  return (
    <Formik
      initialValues={{ name: '' }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Subtopic Name is required.'),
      })}
      validateOnBlur={true}
      validateOnMount={true}
      onSubmit={async ({ name }) => {
        await onConfirm(name);
      }}
    >
      {(formikProps) => {
        return (
          <Form>
            <AlertModal
              title={'เพิ่ม Subtopic'}
              onConfirm={async () => {
                await formikProps.handleSubmit();
              }}
              cancelButtonProps={{ disabled: formikProps.isSubmitting }}
              confirmButtonProps={{ disabled: !formikProps.isValid || formikProps.isSubmitting }}
              content={
                <FormikTextField
                  name={'name'}
                  label={'SUBTOPIC NAME'}
                  fullWidth={true}
                  disabled={formikProps.isSubmitting}
                />
              }
              triggerComponent={(props) => (
                <Button variant="outlined" color="secondary" startIcon={<AddIcon />} {...props}>
                  เพิ่ม SUBTOPIC
                </Button>
              )}
            />
            <Loading.Modal open={formikProps.isSubmitting} />
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddNewSubtopicModal;

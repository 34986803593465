import * as Yup from 'yup';
import { TextField } from '../../../Components/Base';

const assessmentTypeOptions = [
  { value: 'FIXED', label: 'FIXED' },
  { value: 'RANDOM', label: 'RANDOM' },
];
export const assessmentDetailFields = {
  // Basic Data
  name: {
    label: 'ASSESSMENT NAME',
    fieldName: 'name',
    required: true,
    validationSchema: Yup.string(),
  },
  description: {
    label: 'DESCRIPTION',
    fieldName: 'description',
    helperText:
      'Description ไม่ได้มีการใช้บนหน้าเว็บจริง ใช้เก็บข้อมูลแบบทดสอบบนเว็บ Backoffice นี้เท่านั้น',
    multiline: true,
    rows: 6,
    validationSchema: Yup.string(),
  },
  backgroundColor: {
    label: 'THEME COLOR',
    fieldName: 'backgroundColor',
    helperText: 'โค้ดสีในรูปแบบของ HEX  - e.g. #000000',
    required: true,
    validationSchema: Yup.string(),
  },
  iconUrl: {
    label: 'ICON URL',
    fieldName: 'iconUrl',
    helperText: 'URL ของรูปไอคอนของ แบบทดสอบ',
    required: true,
    validationSchema: Yup.string(),
  },
  badge: {
    label: 'BADGE',
    fieldName: 'badge',
    helperText: 'Badge เช่น “NEW” (ดูค่าที่เป็นไปได้อื่นในโค้ด Backend)',
    validationSchema: Yup.string(),
  },
  category: {
    label: 'Category',
    fieldName: 'category',
    helperText: 'Category Code (จัดการค่าใน Rowy)',
    validationSchema: Yup.string(),
  },
  shareRedirectUrl: {
    label: 'SHARE REDIRECT URL',
    fieldName: 'shareRedirectUrl',
    helperText:
      'Redirect URL เมื่อคลิกจากโพสต์ Social Media หลังจากแชร์คะแนน (default to https://skillscore.skooldio.com)',
    validationSchema: Yup.string(),
  },
  instructions: {
    fieldName: 'instructions',
    validationSchema: Yup.array().of(Yup.string()),
  },
  displaySubtopics: {
    fieldName: 'displaySubtopics',
    validationSchema: Yup.array().of(Yup.string()),
  },

  // Assessment Data
  fullScore: {
    label: 'FULL SCORE',
    fieldName: 'fullScore',
    required: true,
    validationSchema: Yup.number(),
    type: 'number',
  },
  timeLimit: {
    label: 'TIME LIMIT (SECONDS)',
    fieldName: 'timeLimit',
    required: true,
    validationSchema: Yup.number(),
    type: 'number',
  },
  type: {
    label: 'ASSESSMENT TYPE',
    fieldName: 'type',
    required: true,
    helperText: 'หากค่าเป็น FIXED โจทย์จะถูกเลือกเรียงตามลำดับ (ไม่มีการ random โจทย์)',
    component: TextField.Select,
    options: assessmentTypeOptions,
    validationSchema: Yup.mixed().oneOf(assessmentTypeOptions.map((o) => o.value)),
  },
  topic: { label: 'Topic', fieldName: 'topic', validationSchema: Yup.string() },
  totalQuestions: {
    label: 'TOTAL QUESTIONS',
    fieldName: 'totalQuestions',
    required: true,
    helperText: 'จำนวนโจทย์ทั้งหมดที่ผู้ทำแบบทดสอบจะต้องทำในแต่ละครั้ง',
    validationSchema: Yup.number(),
    type: 'number',
  },
  showNotKnowChoice: {
    label: 'แสดงตัวเลือก “ฉันไม่รู้คำตอบข้อนี้”',
    fieldName: 'metadata.showNotKnowChoice',
    helperText:
      'หาก checked ระบบจะเพิ่มตัวเลือก "ฉันไม่รู้คำตอบข้อนี้" เป็นตัวเลือกสุดท้ายของโจทย์ประเภท Multiple Choices',
    component: TextField.Checkbox,
    validationSchema: Yup.boolean(),
  },
};

const conditionTypeOptions = [{ value: 'SCORE_LESS_THAN', label: 'SCORE_LESS_THAN' }];
export const BLANK_BANNER = {
  title: '',
  description: '',
  imageUrl: '',
  redirectUrl: '',
  condition: {
    type: '',
    metadata: {
      score: 0,
    },
  },
};
export const bannerFields = {
  title: { label: 'TITLE', fieldName: 'title', required: true, validationSchema: Yup.string() },
  description: {
    label: 'DESCRIPTION',
    fieldName: 'description',
    required: true,
    validationSchema: Yup.string(),
    multiline: true,
    rows: 6,
  },
  imageUrl: {
    label: 'THUMBNAIL IMAGE URL',
    fieldName: 'imageUrl',
    required: true,
    validationSchema: Yup.string(),
  },
  redirectUrl: {
    label: 'REDIRECT URL',
    fieldName: 'redirectUrl',
    required: true,
    helperText: 'URL ที่จะลิงก์ไปหลังจากกด Banner นี้',
    validationSchema: Yup.string(),
  },
  conditionType: {
    label: 'CONDITION',
    fieldName: 'condition.type',
    required: true,
    validationSchema: Yup.mixed().oneOf(conditionTypeOptions.map((o) => o.value)),
    component: TextField.Select,
    options: conditionTypeOptions,
  },
  conditionScore: {
    label: 'SCORE',
    fieldName: 'condition.metadata.score',
    required: true,
    type: 'number',
    validationSchema: Yup.number(),
  },
};

export const subtopicFields = {
  name: {
    label: 'SUBTOPIC NAME',
    fieldName: 'name',
    required: true,
    validationSchema: Yup.string().required(),
  },
  totalQuestionsBySubtopic: {
    label: 'TOTAL QUESTIONS BY SUBTOPIC',
    fieldName: 'totalQuestionsBySubtopic',
    required: true,
    validationSchema: Yup.number().required(),
    type: 'number',
  },
};

import React from 'react';

const useModal = () => {
  const [modalOpen, setModalOpen] = React.useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return { modalOpen, handleModalOpen, handleModalClose };
};

export default useModal;

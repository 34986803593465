import React from 'react';
import Grid from '@material-ui/core/Grid';

import LayoutContainer from './Components/LayoutContainer';
import GeneralData from './Components/GeneralData';
import Instructions from './Components/Instructions';
import DisplaySubtopics from './Components/DisplaySubtopics';
import Banners from './Components/Banners';
import AssessmentData from './Components/AssessmentData';
import Subtopics from './Components/Subtopics';
import QuestionListTable from './Components/QuestionListTable';
import ImportQuestions from './Components/ImportQuestions';

const AssessmentDetail = ({
  assessment,
  onUpdate,
  onSubtopicUpdate,
  onSubtopicAdd,
  onSubtopicDelete,
  onSubtopicOrderUpdate,
  onPublishAssessment,
  onUnpublishAssessment,
  onDeleteAssessment,
  onImportQuestions,
  onGetQuestionCsv,
}) => {
  return (
    <LayoutContainer
      assessment={assessment}
      onPublishAssessment={onPublishAssessment}
      onUnpublishAssessment={onUnpublishAssessment}
      onDeleteAssessment={onDeleteAssessment}
      tabs={[
        {
          label: 'Basic Data',
          children: (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <GeneralData data={assessment} onUpdate={onUpdate} />
              </Grid>

              <Grid item xs={6}>
                <Instructions instructions={assessment.instructions} onUpdate={onUpdate} />
              </Grid>
              <Grid item xs={6}>
                <DisplaySubtopics
                  displaySubtopics={assessment.displaySubtopics}
                  onUpdate={onUpdate}
                />
              </Grid>

              <Grid item xs={12}>
                <Banners banners={assessment.banners} onUpdate={onUpdate} />
              </Grid>
            </Grid>
          ),
        },
        {
          label: 'Assessment Data',
          children: (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <AssessmentData data={assessment} onUpdate={onUpdate} />
              </Grid>
              <Grid item xs={12}>
                <Subtopics
                  subtopics={assessment.subtopics}
                  onSubtopicUpdate={onSubtopicUpdate}
                  onSubtopicAdd={onSubtopicAdd}
                  onSubtopicDelete={onSubtopicDelete}
                  onSubtopicOrderUpdate={onSubtopicOrderUpdate}
                />
              </Grid>
            </Grid>
          ),
        },
        {
          label: 'Questions',
          children: (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <QuestionListTable
                  questions={assessment.questions}
                  onGetQuestionCsv={onGetQuestionCsv}
                />
              </Grid>
            </Grid>
          ),
        },
        {
          label: 'Import Questions',
          children: (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ImportQuestions assessment={assessment} onImportQuestions={onImportQuestions} />
              </Grid>
            </Grid>
          ),
        },
      ]}
    />
  );
};

export default AssessmentDetail;

import React from 'react';

import { Loading } from '../../Components/Base';
import { fetchUsersWithRole, fetchSetUserRole, fetchUnsetUserRole } from '../../apis/user';

import UserPermissionView from './UserPermission.view';

const UserPermission = () => {
  const [users, setUsers] = React.useState([]);

  React.useEffect(() => {
    handleGetUserWithRoles();
  }, []);

  const handleGetUserWithRoles = async () => {
    const userWithRoles = await fetchUsersWithRole();
    setUsers(userWithRoles);
  };
  const handleSetAdmin = async (email) => {
    // Currently, there is only ADMIN roles. Other roles can be added via fetchSetUserRole too, but the roles need to be defined in the API first.
    await fetchSetUserRole(email, 'ADMIN');
    await handleGetUserWithRoles();
  };
  const handleUnsetAdmin = async (userId) => {
    await fetchUnsetUserRole(userId);
    await handleGetUserWithRoles();
  };

  return (
    <UserPermissionView onSetAdmin={handleSetAdmin} onUnsetAdmin={handleUnsetAdmin} users={users} />
  );
};

export default UserPermission;

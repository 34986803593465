import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
    backgroundColor: '#fff',
  },
}));
const Loading = () => {
  return (
    <Box display={'flex'} justifyContent={'center'} width={'100%'} p={4}>
      <CircularProgress color="primary" />
    </Box>
  );
};

const LoadingModal = ({ open }) => {
  return (
    <Dialog open={open} disableBackdropClick maxWidth={'xs'}>
      <DialogContent>
        <Loading />
      </DialogContent>
    </Dialog>
  );
};

function FullPageLoading({ open, onClose }) {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={open} onClick={onClose}>
      <Loading />
    </Backdrop>
  );
}

FullPageLoading.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

Loading.Modal = LoadingModal;
Loading.FullPage = FullPageLoading;

export default Loading;

import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import NoteIcon from '@material-ui/icons/Note';
import PeopleIcon from '@material-ui/icons/People';

import Assessments from '../Assessments';
import AssessmentDetail from '../AssessmentDetail';
import Login from '../Login';
import UserPermission from '../UserPermission';
import getConfig from '../../Utils/config';

const config = getConfig();

function RowyRedirect() {
  useEffect(() => {
    window.location.replace(config.rowyUrl);

    return () => {};
  }, []);

  return <div>Redirect...</div>;
}

const routes = {
  HOME: {
    name: 'Home',
    path: '/',
    exact: true,
    component: () => <Redirect to={'/assessments'} />,
  },
  ROWY: {
    name: 'Rowy',
    path: '/rowy',
    exact: true,
    component: () => <RowyRedirect />,
  },
  ASSESSMENTS: {
    name: 'Assessments',
    path: '/assessments',
    exact: true,
    component: Assessments,
  },
  ASSESSMENT_DETAIL: {
    name: 'Assessment Detail',
    path: '/assessments/:assessmentId',
    exact: true,
    component: AssessmentDetail,
  },
  USER_PERMISSION: {
    name: 'Users & Permissions',
    path: '/user-permission',
    exact: true,
    component: UserPermission,
  },
  LOGIN: {
    name: 'Login',
    path: '/login',
    exact: true,
    component: Login,
  },
  NO_MATCH: {
    name: 'No Match',
    path: '*',
    exact: false,
    component: () => <div>404 - Page Not Found</div>,
  },
};

const menuRoutes = [
  { icon: HomeIcon, ...routes.HOME },
  { icon: NoteIcon, ...routes.ASSESSMENTS },
  { icon: NoteIcon, ...routes.ROWY },
];

const appSettingsMenuRoutes = [{ icon: PeopleIcon, ...routes.USER_PERMISSION }];

const renderRoutes = [
  routes.HOME,
  routes.ASSESSMENTS,
  routes.ASSESSMENT_DETAIL,
  routes.USER_PERMISSION,
];
const unAuthenticatedRoutes = [routes.LOGIN, routes.ROWY, routes.NO_MATCH];

export { menuRoutes, appSettingsMenuRoutes, renderRoutes, routes, unAuthenticatedRoutes };
export default routes;

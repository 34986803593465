import React from 'react';
import { useParams, useHistory, generatePath } from 'react-router-dom';

import { Loading } from '../../Components/Base';
import {
  fetchAssessmentById,
  fetchUpdateAssessment,
  fetchUpdateAssessmentSubtopic,
  fetchCreateAssessmentSubtopic,
  fetchDeleteAssessmentSubtopic,
  fetchPublishAssessment,
  fetchUnpublishAssessment,
  fetchDeleteAssessment,
  fetchImportQuestions,
  fetchDownloadQuestionCsv,
} from '../../apis/assessment';
import routes from '../Main/routes';

import AssessmentDetailView from './AssessmentDetail.view';

const formatSubtopicQuestions = (subtopicName, subtopicQuestions, requiredQuestionsBySubtopic) => {
  const formattedSubtopicQuestions = subtopicQuestions.map((q, index) => {
    return { id: q, subtopic: subtopicName };
  });

  let required;
  if (requiredQuestionsBySubtopic) {
    const { random, ...fixedObj } = requiredQuestionsBySubtopic;
    required = { random, fixed: fixedObj };

    if (random) {
      random.forEach((randomQuestionId) => {
        const questionIndex = formattedSubtopicQuestions.findIndex(
          (q) => q.id === randomQuestionId
        );
        if (questionIndex !== -1) {
          formattedSubtopicQuestions[questionIndex].required = true;
        }
      });
    }

    if (fixedObj) {
      const fixedArr = Object.entries(fixedObj);

      fixedArr.forEach(([requiredAtIndex, fixedQuestionId]) => {
        const questionIndex = formattedSubtopicQuestions.findIndex((q) => q.id === fixedQuestionId);
        formattedSubtopicQuestions[questionIndex].required = true;
        formattedSubtopicQuestions[questionIndex].requiredAtIndex = requiredAtIndex;
      });
    }
  }
  return { questions: formattedSubtopicQuestions, requiredQuestions: required };
};

const formatAssessment = (assessment) => {
  const {
    subtopics: subt,
    metadata: { orderOfSubtopics, questions, requiredQuestionsBySubtopic, ...restMeta },
    totalQuestionsBySubtopic,
    ...restAssessmentData
  } = assessment || {};

  const sortedSubtopics = orderOfSubtopics || subt;
  const subtopics = sortedSubtopics.map((subtopic) => {
    let questionsBySubtopic;
    let reqruiedQuestionsBySubtopic;

    /**
     * If questions is an array, it means that the assessment type is "FIXED" and there will be no questions by subtopics.
     * If there are questions by subtopics, the questions must be an object.
     */
    if (!Array.isArray(questions)) {
      const subtopicQuestions = questions && questions[subtopic] ? questions[subtopic] : [];

      const { questions: formattedSubtopicQuestions, requiredQuestions } = formatSubtopicQuestions(
        subtopic,
        subtopicQuestions,
        requiredQuestionsBySubtopic ? requiredQuestionsBySubtopic[subtopic] : {}
      );
      questionsBySubtopic = formattedSubtopicQuestions;
      reqruiedQuestionsBySubtopic = requiredQuestions;
    }

    return {
      name: subtopic,
      totalQuestionsBySubtopic: totalQuestionsBySubtopic[subtopic],
      questions: questionsBySubtopic,
      requiredQuestionsBySubtopic: reqruiedQuestionsBySubtopic,
    };
  });

  const allQuestions = Array.isArray(questions)
    ? questions.map((q) => ({ id: q }))
    : subtopics.reduce((acc, curr) => {
        const { questions } = curr;
        return [...acc, ...questions];
      }, []);

  return { ...restAssessmentData, metadata: { ...restMeta }, subtopics, questions: allQuestions };
};
const AssessmentDetail = () => {
  const { assessmentId } = useParams();
  const history = useHistory();
  const [assessment, setAssessment] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const getAssessment = async () => {
      setLoading(true);
      try {
        const { assessment } = await fetchAssessmentById(assessmentId);
        const formattedAssessment = formatAssessment(assessment);
        setAssessment(formattedAssessment);
        setLoading(false);
      } catch (err) {
        console.error(err);
        alert(err.message);
        history.push(generatePath(routes.ASSESSMENTS.path));
      }
    };
    getAssessment();
  }, []);

  const handleDataUpdate = async (updateValues) => {
    try {
      const { assessment } = await fetchUpdateAssessment(assessmentId, updateValues);
      const formattedAssessment = formatAssessment(assessment);
      setAssessment(formattedAssessment);
    } catch (err) {
      console.error(err);
      alert(err.message);
      throw err;
    }
  };

  const handleSubtopicUpdate = async (subtopicName, values) => {
    const { assessment } = await fetchUpdateAssessmentSubtopic(assessmentId, subtopicName, values);
    const formattedAssessment = formatAssessment(assessment);
    setAssessment(formattedAssessment);
  };

  const handleSubtopicAdd = async (subtopicName) => {
    const { assessment } = await fetchCreateAssessmentSubtopic(assessmentId, subtopicName);
    const formattedAssessment = formatAssessment(assessment);
    setAssessment(formattedAssessment);
  };

  const handleSubtopicDelete = async (subtopicName) => {
    const { assessment } = await fetchDeleteAssessmentSubtopic(assessmentId, subtopicName);
    const formattedAssessment = formatAssessment(assessment);
    setAssessment(formattedAssessment);
  };

  const handleSubtopicOrderUpdate = async (values) => {
    const { orderOfSubtopics } = values;
    try {
      const { assessment } = await fetchUpdateAssessment(assessmentId, {
        metadata: { orderOfSubtopics: orderOfSubtopics },
      });
      const formattedAssessment = formatAssessment(assessment);
      setAssessment(formattedAssessment);
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const handlePublishAssessment = async () => {
    try {
      const { assessment } = await fetchPublishAssessment(assessmentId);
      const formattedAssessment = formatAssessment(assessment);
      setAssessment(formattedAssessment);
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const handleUnpublishAssessment = async () => {
    try {
      const { assessment } = await fetchUnpublishAssessment(assessmentId);
      const formattedAssessment = formatAssessment(assessment);
      setAssessment(formattedAssessment);
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const handleDeleteAssessment = async () => {
    try {
      await fetchDeleteAssessment(assessmentId);
      history.push(generatePath(routes.ASSESSMENTS.path));
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const handleImportQuestions = async (assessmentId, html, params) => {
    try {
      await fetchImportQuestions(assessmentId, html, params);
      const { assessment } = await fetchAssessmentById(assessmentId);
      const formattedAssessment = formatAssessment(assessment);
      setAssessment(formattedAssessment);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const handleGetQuestionCsv = async () => {
    try {
      await fetchDownloadQuestionCsv(assessmentId);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <AssessmentDetailView
      assessment={assessment}
      onUpdate={handleDataUpdate}
      onSubtopicUpdate={handleSubtopicUpdate}
      onSubtopicAdd={handleSubtopicAdd}
      onSubtopicDelete={handleSubtopicDelete}
      onSubtopicOrderUpdate={handleSubtopicOrderUpdate}
      onPublishAssessment={handlePublishAssessment}
      onUnpublishAssessment={handleUnpublishAssessment}
      onDeleteAssessment={handleDeleteAssessment}
      onImportQuestions={handleImportQuestions}
      onGetQuestionCsv={handleGetQuestionCsv}
    />
  );
};

export default AssessmentDetail;

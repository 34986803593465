import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import { Card } from '../../../../Components/Base';
import useModal from '../../../../hooks/useModal';
import BannerAccordion from './BannerAccordion';
import EditOrderOfBannersModal from './EditOrderOfBannersModal';
import BannerDetailFormModal from './BannerDetailFormModal';
import { BLANK_BANNER } from '../utils';

const AddNewBannerModal = ({ onSubmit }) => {
  const { modalOpen, handleModalOpen, handleModalClose } = useModal();
  return (
    <>
      <BannerDetailFormModal
        title={'เพิ่ม Banner'}
        banner={BLANK_BANNER}
        onSubmit={onSubmit}
        open={modalOpen}
        onClose={handleModalClose}
        confirmButtonText={'เพิ่ม Banner'}
      />
      <Button
        variant={'outlined'}
        color={'secondary'}
        startIcon={<AddIcon />}
        onClick={handleModalOpen}
      >
        เพิ่ม Banner
      </Button>
    </>
  );
};

const Banners = ({ banners, onUpdate }) => {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleAccordionExpand = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleBannerDelete = async (index) => {
    const newBanners = [...banners];
    newBanners.splice(index, 1);
    await onUpdate({ banners: newBanners });
  };

  const handleBannerAdd = async (banner) => {
    const newBanners = [...banners, banner];
    await onUpdate({ banners: newBanners });
  };

  const handleBannerFieldUpdate = async (index, values) => {
    const updateBanner = { ...banners[index], ...values };
    const newBanners = [...banners];
    newBanners[index] = updateBanner;
    await onUpdate({ banners: newBanners });
  };

  const handleBannerOrderUpdate = async (values) => {
    const newBanners = values.banners.map((bannerTitle) =>
      banners.find((b) => b.title === bannerTitle)
    );
    await onUpdate({ banners: newBanners });
  };
  return (
    <>
      <Card style={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>
        <Card.Header
          title={'Banners'}
          action={
            <EditOrderOfBannersModal
              onUpdate={handleBannerOrderUpdate}
              banners={banners}
              disabled={banners.length <= 0}
            />
          }
        />
      </Card>
      <Box mb={'1px'} />
      {banners.map((banner, index) => {
        const { title } = banner;

        const panelId = `panel-${index}-${title}`;
        return (
          <BannerAccordion
            expanded={expanded === panelId}
            onDelete={async () => {
              await handleBannerDelete(index);
            }}
            banner={banner}
            onUpdate={(values) => handleBannerFieldUpdate(index, values)}
            onClickAccordion={handleAccordionExpand(panelId)}
            key={panelId}
          />
        );
      })}
      <Box mt={3}>
        <AddNewBannerModal onSubmit={handleBannerAdd} />
      </Box>
    </>
  );
};

Banners.defaultProps = {
  banners: [],
};

export default Banners;

import React from 'react';

import Box from '@material-ui/core/Box';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';

import AlertModal from '../../../../Components/AlertModal';
import { Loading } from '../../../../Components/Base';
import useModal from '../../../../hooks/useModal';

import Accordion from '../Accordion';
import SubtopicDetailFormModal from './SubtopicDetailFormModal';
import { SubtopicDetailForm, SubtopicDetailFormikWrapper } from './SubtopicDetailForm';

const SubtopicAccordion = ({ subtopic, onDelete, onUpdate, expanded, onClickAccordion }) => {
  const { modalOpen, handleModalOpen, handleModalClose } = useModal();
  const { name, totalQuestionsBySubtopic, requiredQuestionsBySubtopic, questions } = subtopic;

  const [deleting, setDeleting] = React.useState(false);

  const { fixed = {}, random = [] } = requiredQuestionsBySubtopic || {};
  const randomCount = random.length;
  const fixedCount = Object.values(fixed).length;
  const requiredQuestionCount = randomCount + fixedCount;

  const panelId = `panel-${name}`;
  return (
    <Accordion
      expanded={expanded}
      onClickAccordion={onClickAccordion}
      key={panelId}
      title={`${name}`}
      subtitle={
        Array.isArray(questions)
          ? `จำนวนโจทย์ทั้งหมดใน Subtopic นี้:  ${questions.length}ข้อ ${
              requiredQuestionCount ? `(${requiredQuestionCount} required)` : ''
            }`
          : `จำนวนโจทย์ทั้งหมดใน Subtopic นี้:  0ข้อ`
      }
      actions={
        <Box display={'flex'}>
          <AlertModal
            title={`ลบ Subtopic`}
            onConfirm={async (e) => {
              setDeleting(true);
              await onDelete();
              setDeleting(false);
            }}
            content={`คุณแน่ใจว่าต้องการลบ Subtopic "${name}"?`}
            triggerComponent={({ onClick, ...restProps }) => (
              <Button
                size={'small'}
                startIcon={<DeleteIcon fontSize="inherit" />}
                onClick={(e) => {
                  e.stopPropagation();
                  onClick();
                }}
                {...restProps}
              >
                ลบ
              </Button>
            )}
          />
          <Button
            aria-label="edit"
            color={'secondary'}
            startIcon={<EditIcon />}
            onClick={(e) => {
              e.stopPropagation();
              handleModalOpen();
            }}
          >
            แก้ไข
          </Button>
        </Box>
      }
    >
      <Box mb={'1px'} />
      <Box width={'100%'}>
        <SubtopicDetailFormikWrapper subtopic={subtopic} readOnly={true}>
          {(formikProps) => <SubtopicDetailForm readOnly={true} />}
        </SubtopicDetailFormikWrapper>
      </Box>
      <SubtopicDetailFormModal
        subtopic={subtopic}
        onSubmit={onUpdate}
        open={modalOpen}
        onClose={handleModalClose}
      />
      <Loading.Modal open={deleting} />
    </Accordion>
  );
};

export default SubtopicAccordion;

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import TextField from './TextField';

const useFormControlLabelStyles = makeStyles((theme) => ({
  labelPlacementStart: {
    justifyContent: 'flex-end',
  },
}));

const FormikCheckbox = ({ name, label, helperText, labelPlacement, ...restProps }) => {
  const formControlLabelClasses = useFormControlLabelStyles();
  return (
    <Field name={name} key={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }) => {
        return (
          <FormControl required error={Boolean(meta.error)} variant={'filled'}>
            <FormControlLabel
              classes={formControlLabelClasses}
              control={
                <Checkbox
                  checked={field.value}
                  onChange={field.onChange}
                  name={name}
                  {...restProps}
                />
              }
              label={label}
              labelPlacement={labelPlacement}
            />
            <FormHelperText>{meta.error ? meta.error : helperText}</FormHelperText>
          </FormControl>
        );
      }}
    </Field>
  );
};
FormikCheckbox.defaultProps = {
  labelPlacement: 'start',
};

const FormikTextField = ({ name, helperText, component, ...restProps }) => {
  const TextFieldComponent = component ? component : TextField;
  return (
    <Field name={name} key={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }) => {
        return (
          <TextFieldComponent
            name={field.name}
            error={Boolean(meta.error)}
            helperText={meta.error ? meta.error : helperText}
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value}
            {...restProps}
          />
        );
      }}
    </Field>
  );
};

FormikTextField.propTypes = {
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
};

FormikTextField.Checkbox = FormikCheckbox;
export default FormikTextField;

import React from 'react';
import Box from '@material-ui/core/Box';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';

import AlertModal from '../../../../Components/AlertModal';
import { Loading } from '../../../../Components/Base';
import useModal from '../../../../hooks/useModal';
import Accordion from '../Accordion';

import { BannerDetailFormikWrapper, BannerDetailForm } from './BannerDetailForm';
import BannerDetailFormModal from './BannerDetailFormModal';

const BannerAccordion = ({ expanded, onClickAccordion, onUpdate, banner, onDelete }) => {
  const { modalOpen, handleModalOpen, handleModalClose } = useModal();
  const { title } = banner;
  const [deleting, setDeleting] = React.useState(false);

  return (
    <Accordion
      expanded={expanded}
      onClickAccordion={onClickAccordion}
      title={title}
      actions={
        <Box display={'flex'}>
          <AlertModal
            title={`ลบ Banner`}
            onConfirm={async () => {
              setDeleting(true);
              await onDelete();
              setDeleting(false);
            }}
            content={`คุณแน่ใจว่าต้องการลบ Banner "${title}"?`}
            triggerComponent={({ onClick, ...restProps }) => (
              <Button
                size={'small'}
                startIcon={<DeleteIcon fontSize="inherit" />}
                onClick={(e) => {
                  e.stopPropagation();
                  onClick();
                }}
                {...restProps}
              >
                ลบ
              </Button>
            )}
          />
          <Button
            aria-label="edit"
            color={'secondary'}
            startIcon={<EditIcon />}
            onClick={(e) => {
              e.stopPropagation();
              handleModalOpen();
            }}
          >
            แก้ไข
          </Button>
        </Box>
      }
    >
      <Box width={'100%'}>
        <BannerDetailFormikWrapper banner={banner} readOnly={true}>
          {(formikProps) => <BannerDetailForm readOnly={true} />}
        </BannerDetailFormikWrapper>
      </Box>
      <BannerDetailFormModal
        banner={banner}
        onSubmit={onUpdate}
        open={modalOpen}
        onClose={handleModalClose}
      />
      <Loading.Modal open={deleting} />
    </Accordion>
  );
};

export default BannerAccordion;

import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

const useAccordianStyles = makeStyles((theme) => ({
  root: {
    '&:first-child': {
      borderRadius: 0,
    },
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  expanded: {
    boxShadow: theme.shadows[3],
    margin: '0 !important',
  },
}));

const useSummaryStyles = makeStyles((theme) => {
  return {
    root: {
      padding: theme.spacing(3, 4),
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
    },
    content: {
      margin: 0,
      '&$expanded': { padding: 0, margin: 0 },
    },
    expanded: {
      padding: theme.spacing(3, 4),
      borderBottom: 'none',
    },
  };
});

const useDetailsStyles = makeStyles((theme) => {
  return {
    root: {
      padding: theme.spacing(3, 4, 1),
      backgroundColor: theme.palette.grey[200],
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
  };
});

const Accordion = ({ expanded, onClickAccordion, title, subtitle, actions, children }) => {
  const accordianClasses = useAccordianStyles();
  const summaryClasses = useSummaryStyles();
  const detailsClasses = useDetailsStyles();

  return (
    <MuiAccordion expanded={expanded} onChange={onClickAccordion} classes={accordianClasses}>
      <MuiAccordionSummary
        classes={summaryClasses}
        aria-controls="panel1d-content"
        id="panel1d-header"
      >
        <Box width={'510px'}>
          <Typography variant={'subtitle1'} style={{ fontWeight: 600 }}>
            {title}
          </Typography>
        </Box>
        <Typography variant={'subtitle1'} color={'textSecondary'}>
          {subtitle}
        </Typography>

        <Box ml={'auto'}>{actions}</Box>
      </MuiAccordionSummary>
      <MuiAccordionDetails classes={detailsClasses}>{children}</MuiAccordionDetails>
    </MuiAccordion>
  );
};

export default Accordion;

import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import { renderRoutes } from '../routes';

import { NAVBAR_WIDTH, LOGO_BAR_HEIGHT } from './constants';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    marginTop: LOGO_BAR_HEIGHT,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginTop: LOGO_BAR_HEIGHT,
    marginLeft: NAVBAR_WIDTH,
    width: `calc(100% - ${NAVBAR_WIDTH})`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
}));

const AppTitle = () => {
  const [title, setTitle] = React.useState(null);
  const location = useLocation();
  React.useEffect(() => {
    const matchRoute = renderRoutes.find((route) => {
      const match = matchPath(location.pathname, {
        path: route.path,
        exact: route.exact,
        strict: true,
      });

      return Boolean(match);
    });

    if (matchRoute) {
      const { name } = matchRoute;
      setTitle(name);
    }
  }, [location]);

  return (
    <Typography variant="h6" noWrap>
      {title}
    </Typography>
  );
};

export default function AppLayout({ children, drawerOpen, onDrawerOpen }) {
  const classes = useStyles();

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: drawerOpen,
      })}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={onDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: drawerOpen,
          })}
        >
          <MenuIcon />
        </IconButton>
        <AppTitle />
      </Toolbar>
    </AppBar>
  );
}

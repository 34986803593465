import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';

import { Container, Loading } from '../../../Components/Base';
import AlertModal from '../../../Components/AlertModal';

const useStyles = makeStyles(() => ({
  titleRoot: {
    fontWeight: 500,
  },
}));

const PublishAssessmentButton = ({
  isAssessmentPublished,
  onPublish,
  onUnpublish,
  assessmentName,
}) => {
  const [loading, setLoading] = React.useState(false);

  const handlePublish = async () => {
    setLoading(true);
    await onPublish();
    setLoading(false);
  };
  const handleUnPublish = async () => {
    setLoading(true);
    await onUnpublish();
    setLoading(false);
  };
  return (
    <>
      {!isAssessmentPublished ? (
        <AlertModal
          title={`Publish แบบทดสอบ "${assessmentName}"`}
          content={
            <span>
              คุณแน่ใจว่าต้องการ Publish แบบทดสอบ "{assessmentName}"? <br /> <br />
              เมื่อ Publish แล้ว แบบทดสอบนี้จะถูกแสดงผลบนหน้า Landing Page
            </span>
          }
          onConfirm={handlePublish}
          confirmText={'ยืนยัน'}
          cancelText={'ยกเลิก'}
          triggerComponent={(props) => (
            <Button variant={'contained'} color={'primary'} {...props}>
              Publish แบบทดสอบ
            </Button>
          )}
        />
      ) : (
        <AlertModal
          title={`Unpublish แบบทดสอบ "${assessmentName}"`}
          content={
            <span>
              คุณแน่ใจว่าต้องการ Unpublish แบบทดสอบ "{assessmentName}"? <br /> <br />
              เมื่อ Unpublish แล้ว แบบทดสอบนี้จะถูกถอดออกจากหน้า Landing Page
            </span>
          }
          onConfirm={handleUnPublish}
          confirmText={'ยืนยัน'}
          cancelText={'ยกเลิก'}
          triggerComponent={(props) => (
            <Button variant={'contained'} color={'secondary'} {...props}>
              Unpublish แบบทดสอบ
            </Button>
          )}
        />
      )}
      <Loading.Modal open={loading} />
    </>
  );
};

const DeleteAssessmentButton = ({ assessmentName, onDelete }) => {
  return (
    <AlertModal
      title={`ลบแบบทดสอบ "${assessmentName}"`}
      content={`คุณแน่ใจว่าต้องการลบแบบทดสอบ "${assessmentName}"?`}
      onConfirm={onDelete}
      confirmText={'ลบแบบทดสอบ'}
      cancelText={'ยกเลิก'}
      triggerComponent={(props) => (
        <Button variant={'outlined'} color={'secondary'} startIcon={<DeleteIcon />} {...props}>
          ลบแบบทดสอบ
        </Button>
      )}
    />
  );
};

const TitleBar = ({
  tabs,
  assessment,
  onPublishAssessment,
  onUnpublishAssessment,
  onDeleteAssessment,
  onTabChange,
  currentTab,
}) => {
  const classes = useStyles();
  return (
    <AppBar position="static" color={'white'}>
      <Box display={'flex'} px={3} py={3} justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant={'h5'} classes={{ root: classes.titleRoot }}>
          {assessment.name}
        </Typography>
        <Box ml={'auto'} mr={2}>
          <Typography variant={'subtitle2'}>
            {assessment.published
              ? 'แบบทดสอบนี้ถูก PUBLISHED แล้ว'
              : 'แบบทดสอบนี้ยังไม่ถูก PUBLISHED'}
          </Typography>
        </Box>

        <PublishAssessmentButton
          isAssessmentPublished={assessment.published}
          assessmentName={assessment.name}
          onPublish={onPublishAssessment}
          onUnpublish={onUnpublishAssessment}
        />

        <Box ml={2}>
          <DeleteAssessmentButton assessmentName={assessment.name} onDelete={onDeleteAssessment} />
        </Box>
      </Box>

      <MuiTabs value={currentTab} onChange={onTabChange}>
        {tabs.map((t, index) => {
          return <MuiTab label={t.label} key={t.label} />;
        })}
      </MuiTabs>
    </AppBar>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function LayoutContainer({
  tabs,
  assessment,
  onPublishAssessment,
  onUnpublishAssessment,
  onDeleteAssessment,
}) {
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <TitleBar
        tabs={tabs}
        assessment={assessment}
        onPublishAssessment={onPublishAssessment}
        onUnpublishAssessment={onUnpublishAssessment}
        onDeleteAssessment={onDeleteAssessment}
        onTabChange={handleTabChange}
        currentTab={tabValue}
      />

      <Container>
        {tabs.map((t, index) => {
          return (
            <TabPanel value={tabValue} key={t.label} index={index}>
              {t.children}
            </TabPanel>
          );
        })}
      </Container>
    </div>
  );
}

LayoutContainer.defaultProps = {
  tabs: [],
};
export default LayoutContainer;

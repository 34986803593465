/**
 * AuthProvider is used to control authentication state and actions (e.g. login, logout) of the app.
 * This app use httpOnly session cookies to control authentication and use role from custom claims to control access.
 *
 * Skooldio auth is only used when login, logout, and getting existing token
 * (if the users have already logged-in to skooldio account on other products).
 */

import React from 'react';

import { Loading } from '../../Components/Base';

import AuthContext from './AuthContext';

import {
  fetchCurrentSkooldioUserToken,
  fetchLoginWithSkooldioAccount,
  fetchLogoutWithSkooldioAccount,
  fetchLoginWithFirebaseAuth,
  fetchLogoutWithFirebaseAuth,
  fetchSessionLogin,
  fetchSessionLogout,
  fetchCurrentUser,
} from '../../apis/auth';

const AUTH_STATE = {
  INITIALIZING: 'INITIALIZING', // Shows that the app is initializing and trying to get current logged-in skooldio user.
  LOGGED_IN: 'LOGGED_IN',
  NOT_LOGGED_IN: 'NOT_LOGGED_IN',
};

const getCurrentSkooldioUser = async () => {
  const { token } = await fetchCurrentSkooldioUserToken();
  const { user } = await fetchLoginWithFirebaseAuth(token);
  const idToken = await user.getIdToken();
  const sessionLoginResponse = await fetchSessionLogin(idToken);
  return sessionLoginResponse;
};

const AuthProvider = (props) => {
  const [authState, setAuthState] = React.useState(AUTH_STATE.INITIALIZING);
  const [userInfo, setUserInfo] = React.useState(null);
  const [loading, setLoading] = React.useState(false); // Set to true when running actions e.g. login, logout.

  /**
   * Check if the user already have httpOnly session cookie.
   */
  React.useEffect(() => {
    getCurrentUser();
  }, []);

  const getCurrentUser = async () => {
    try {
      /**
       * Check if the user has SkillScore session cookie.
       */
      const { user } = await fetchCurrentUser();
      if (user) {
        setAuthState(AUTH_STATE.LOGGED_IN);
        setUserInfo(user);
      }
    } catch (getCurrentUserError) {
      console.error(getCurrentUserError);
      /**
       * If the user has no SkillScore session cookie, check if the user has Skooldio account session cookie and automatically login.
       */
      try {
        const { success } = await getCurrentSkooldioUser();
        if (success) {
          setAuthState(AUTH_STATE.LOGGED_IN);
        }
      } catch (getCurrentSkooldioUserError) {
        console.error(getCurrentSkooldioUserError);
        setAuthState(AUTH_STATE.NOT_LOGGED_IN);
        setUserInfo(null);
      }
    }
  };

  /**
   * Login both skooldio auth and firebase auth.
   * - Login with skooldio account to get the token which will be used to login into firebase auth.
   * - Use the custom token to loginWithFirebaseAuth()
   */
  const login = async ({ email, password }) => {
    setLoading(true);
    try {
      const { token } = await fetchLoginWithSkooldioAccount({ email, password });
      const { user } = await fetchLoginWithFirebaseAuth(token);
      const idToken = await user.getIdToken();
      await fetchSessionLogin(idToken);
      await getCurrentUser();
    } catch (error) {
      setAuthState(AUTH_STATE.NOT_LOGGED_IN);
      throw new Error(error);
    }
    setLoading(false);
  };

  /**
   * Logout from both skooldio auth and firebase auth.
   * If user is already logged out from skooldio auth, fetchLogoutWithSkooldioAccount() will error and we need to catch it.
   * (in case they logout from other skooldio products e.g. www.skooldio.com, www.skillscore.skooldio.com)
   */
  const logout = async () => {
    setLoading(true);
    try {
      await fetchLogoutWithSkooldioAccount();
    } catch (error) {
      console.error(error); // Known error => Catch this error in case user might already logged out from Skooldio.
    }

    try {
      await fetchLogoutWithFirebaseAuth();
    } catch (error) {
      console.error(error);
    }

    try {
      await fetchSessionLogout();
      setAuthState(AUTH_STATE.NOT_LOGGED_IN);
      setUserInfo(null);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  if (authState === AUTH_STATE.INITIALIZING) {
    return <Loading.FullPage open={true} />;
  }

  return (
    <AuthContext.Provider
      value={{ authState, AUTH_STATE, loading, login, logout, userInfo }}
      {...props}
    />
  );
};

export default AuthProvider;

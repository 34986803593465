import React from 'react';
import { Redirect } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

import { useAuthContext } from '../../domains/auth/hooks';
import { TextField, Loading } from '../../Components/Base';
import routes from '../Main/routes';

const FormikTextField = ({ name, label, textFieldProps }) => {
  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }) => {
        const { name, value, onChange, onBlur } = field;
        const { error, touched } = meta;
        return (
          <TextField
            label={label}
            name={name}
            value={value}
            error={Boolean(error)}
            helperText={error}
            fullWidth={true}
            onChange={onChange}
            onBlur={onBlur}
            InputLabelProps={{
              shrink: true,
            }}
            {...textFieldProps}
          />
        );
      }}
    </Field>
  );
};
const Login = () => {
  const { authState, AUTH_STATE, login } = useAuthContext();

  if (authState === AUTH_STATE.LOGGED_IN) {
    return <Redirect to={routes.HOME.path} />;
  }

  return (
    <Paper style={{ width: '420px', margin: '0 auto' }}>
      <Box display={'flex'} flexDirection={'column'} p={5} alignItems={'stretch'} mx={'auto'}>
        <Box mb={4}>
          <Typography variant={'h5'} align={'center'}>
            Login
          </Typography>
        </Box>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email().required(),
            password: Yup.string().required(),
          })}
          onSubmit={async (values) => {
            try {
              await login({ email: values.email, password: values.password });
            } catch (err) {
              console.error(err);
              alert('Failed to login. Please check your username, password and try again.');
            }
          }}
        >
          {({ isSubmitting, isValid }) => {
            return (
              <Form>
                <Box mb={3}>
                  <FormikTextField
                    label={'Email'}
                    fullWidth={true}
                    name={'email'}
                    textFieldProps={{ fullWidth: true, autoComplete: 'email', type: 'email' }}
                  />
                </Box>
                <Box mb={3}>
                  <FormikTextField
                    label={'Password'}
                    fullWidth={true}
                    name={'password'}
                    textFieldProps={{ fullWidth: true, autoComplete: 'password', type: 'password' }}
                  />
                </Box>

                <Button
                  type={'submit'}
                  variant={'contained'}
                  color={'primary'}
                  fullWidth={true}
                  disabled={!isValid || isSubmitting}
                >
                  {isSubmitting ? 'Loading...' : 'Login'}
                </Button>
                <Loading.Modal open={isSubmitting} />
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Paper>
  );
};

export default Login;

import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import EditIcon from '@material-ui/icons/Edit';

import useModal from '../../../hooks/useModal';
import { Card, Loading } from '../../../Components/Base';

import FormikFieldList from './FormikFieldList';
import { assessmentDetailFields as fields } from './utils';

const leftFields = [fields.name, fields.description, fields.category];
const rightFields = [fields.backgroundColor, fields.iconUrl, fields.badge, fields.shareRedirectUrl];

const validationSchema = Yup.object().shape({
  name: fields.name.validationSchema,
  description: fields.description.validationSchema,
  iconUrl: fields.iconUrl.validationSchema,
  badge: fields.badge.validationSchema,
  backgroundColor: fields.backgroundColor.validationSchema,
  shareRedirectUrl: fields.shareRedirectUrl.validationSchema,
  category: fields.category.validationSchema,
});

const GeneralDataFormikWrapper = ({ assessment, onSubmit, readOnly, children }) => {
  const { name, description, iconUrl, badge, backgroundColor, shareRedirectUrl, category } = assessment || {};
  return (
    <Formik
      initialValues={{
        name,
        description,
        iconUrl,
        badge,
        backgroundColor,
        shareRedirectUrl,
        category,
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={async (values) => {
        if (readOnly) {
          return;
        }
        await onSubmit(values);
      }}
    >
      {(formikProps) => (
        <Form>
          {children(formikProps)}
          <Loading.Modal open={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

const GeneralDataForm = ({ readOnly }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FormikFieldList fields={leftFields} readOnly={readOnly} />
      </Grid>
      <Grid item xs={6}>
        <FormikFieldList fields={rightFields} readOnly={readOnly} />
      </Grid>
    </Grid>
  );
};

const EditGeneralDataModal = ({ assessment, open, onClose, onSubmit }) => {
  const handleSubmit = async (values) => {
    try {
      await onSubmit(values);
      onClose();
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <GeneralDataFormikWrapper assessment={assessment} onSubmit={handleSubmit}>
      {(formikProps) => (
        <Dialog
          open={open}
          disableBackdropClick
          onClose={onClose}
          fullWidth={true}
          maxWidth={'md'}
          scroll={'paper'}
        >
          <DialogTitle>แก้ไขข้อมูลทั่วไป</DialogTitle>
          <DialogContent dividers>
            <GeneralDataForm />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                formikProps.resetForm();
                onClose();
              }}
              disabled={formikProps.isSubmitting}
              type={'button'}
            >
              ยกเลิก
            </Button>
            <Button
              type={'submit'}
              onClick={formikProps.handleSubmit}
              color="primary"
              variant={'contained'}
              disabled={formikProps.isSubmitting || !formikProps.dirty || !formikProps.isValid}
            >
              ยืนยันการแก้ไข
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </GeneralDataFormikWrapper>
  );
};

const GeneralData = ({ data, onUpdate }) => {
  const { modalOpen, handleModalOpen, handleModalClose } = useModal();
  return (
    <>
      <Card>
        <Card.Header
          title={'ข้อมูลทั่วไป'}
          action={
            <Button
              aria-label="edit"
              color={'secondary'}
              startIcon={<EditIcon />}
              onClick={handleModalOpen}
            >
              แก้ไข
            </Button>
          }
        />
        <Card.Content>
          <GeneralDataFormikWrapper assessment={data} readOnly={true}>
            {(formikProps) => <GeneralDataForm readOnly={true} />}
          </GeneralDataFormikWrapper>
        </Card.Content>
      </Card>
      <EditGeneralDataModal
        assessment={data}
        onSubmit={onUpdate}
        open={modalOpen}
        onClose={handleModalClose}
      />
    </>
  );
};

export default GeneralData;

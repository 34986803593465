import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { DataGrid } from '@material-ui/data-grid';
import Box from '@material-ui/core/Box';
import RemoveIcon from '@material-ui/icons/Remove';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';

import { FormikTextField, Loading, Container, Card } from '../../Components/Base';
import AlertModal from '../../Components/AlertModal';

const getColumns = ({ onUnsetAdmin }) => [
  { field: 'firstName', headerName: 'First Name', width: 180 },
  { field: 'lastName', headerName: 'Last Name', width: 180 },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
  },
  {
    field: 'role',
    headerName: 'ROLE',
    width: 180,
  },
  {
    field: '',
    headerName: 'Actions',
    sortable: false,
    width: 240,
    renderCell: (params) => {
      const userId = params.getValue('id');
      const email = params.getValue('email');
      return (
        <RemoveAdminModal email={email} onConfirm={() => onUnsetAdmin(userId)}>
          Remove user from Admin
        </RemoveAdminModal>
      );
    },
  },
];

const AddNewAdminModal = ({ onConfirm }) => {
  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={Yup.object().shape({
        email: Yup.string().required('Email is required.'),
      })}
      validateOnBlur={true}
      onSubmit={async ({ email }) => {
        try {
          await onConfirm(email);
        } catch (err) {
          alert(err.message);
        }
      }}
    >
      {(formikProps) => {
        return (
          <Form>
            <AlertModal
              title={'Add ADMIN users'}
              onConfirm={async () => {
                await formikProps.handleSubmit();
              }}
              onCancel={() => {
                formikProps.resetForm();
              }}
              cancelButtonProps={{ disabled: formikProps.isSubmitting }}
              confirmButtonProps={{
                disabled: !formikProps.isValid || formikProps.isSubmitting || !formikProps.dirty,
              }}
              content={
                <FormikTextField
                  name={'email'}
                  label={'Email'}
                  fullWidth={true}
                  disabled={formikProps.isSubmitting}
                />
              }
              triggerComponent={(props) => (
                <Button
                  variant={'outlined'}
                  color={'secondary'}
                  startIcon={<GroupAddIcon />}
                  {...props}
                >
                  Add ADMIN users
                </Button>
              )}
            />
            <Loading.Modal open={formikProps.isSubmitting} />
          </Form>
        );
      }}
    </Formik>
  );
};

const RemoveAdminModal = ({ onConfirm, email }) => {
  const [submitting, setSubmitting] = React.useState(false);

  return (
    <>
      <AlertModal
        title={`Remove ADMIN`}
        onConfirm={async () => {
          setSubmitting(true);
          try {
            await onConfirm();
            setSubmitting(false);
          } catch (err) {
            alert(err.message);
            setSubmitting(false);
          }
        }}
        cancelButtonProps={{ disabled: submitting }}
        confirmButtonProps={{ disabled: submitting }}
        content={
          <>
            Remove <strong>{email}</strong> from ADMIN role. <br /> This action will only remove the
            permission to access Backoffice (The user will not be deleted from Skill Score).
          </>
        }
        triggerComponent={(props) => (
          <Button
            variant={'contained'}
            color={'primary'}
            startIcon={<RemoveIcon />}
            type={'button'}
            {...props}
          >
            Remove Admin
          </Button>
        )}
      />
      <Loading.Modal open={submitting} />
    </>
  );
};

const UserPermission = ({ users, onSetAdmin, onUnsetAdmin }) => {
  const columns = getColumns({ onUnsetAdmin });
  return (
    <Container>
      <Card>
        <Card.Header title={'Admin Users'} action={<AddNewAdminModal onConfirm={onSetAdmin} />} />

        <Card.Content noPadding>
          <DataGrid
            rows={users}
            columns={columns.map((column) => ({
              ...column,
              disableClickEventBubbling: true,
            }))}
            pageSize={20}
            disableColumnMenu={true}
            autoHeight={true}
          />
        </Card.Content>
      </Card>
    </Container>
  );
};

export default UserPermission;

import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import ThemeProvider from './Components/Theme';
import AuthProvider from './domains/auth/AuthProvider';
import RouteApp from './Routes';
import getConfig from './Utils/config';
const { firebaseConfig } = getConfig();

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// As httpOnly cookies are to be used, do not persist any state client side.
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);

function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <RouteApp />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
